import React from 'react';
import { Controller } from 'react-hook-form';
import { Select, MenuItem, Typography } from '@mui/material';

type DropdownQuestionProps = {
  control: any;
  name: string;
  question: string;
  options?: string[];
  response: number | string | Date | boolean | string[][];
};

const DropdownQuestion: React.FC<DropdownQuestionProps> = ({
  control,
  name,
  question,
  options,
  response,
}) => {
  return (
    <div>
      <Typography variant="h6">{question}</Typography>
      <Controller
        name={name}
        control={control}
        defaultValue={response}
        render={({ field }) => (
          <Select
            {...field}
            value={field.value}
            onChange={(event) => field.onChange(parseInt(event.target.value))}
            displayEmpty
          >
            {options &&
              options.map((option, idx) => (
                <MenuItem key={option} value={idx}>
                  {" "}
                  {option}
                </MenuItem>
              ))}
          </Select>
        )}
      />
    </div>
  );
};

export default DropdownQuestion;