import { Controller } from "react-hook-form";
import { Box, TextField, Typography } from "@mui/material";
import ToggleGroupQuestion from "../components/ToggleGroupQuestion";
import { useEffect } from "react";

type ExerciseBoxProps = {
  control: any;
  watch: any;
  resetField: any;
};

const ExerciseBox: React.FC<ExerciseBoxProps> = ({
  control,
  watch,
  resetField,
}) => {
  const exercise = watch("exercise.exercise");

  // Reset exerciseType and exerciseDuration when exercise is set to 0
  useEffect(() => {
    console.log("exercise", exercise);
    if (exercise === 0) {
      resetField("exercise.exerciseType");
      resetField("exercise.exerciseDuration");
    }
  }, [exercise, resetField]);

  return (
    <Box mt={3} mb={3}>
      <Typography variant="h6">Exercise</Typography>
      <ToggleGroupQuestion
        control={control}
        name="exercise.exercise"
        question="How often do you exercise?"
        options={["Rarely", "At least weekly", "A few times a week", "Daily"]}
        response=""
      />
      {exercise !== 0 && (
        <>
          <Controller
            name="exercise.exerciseType"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="What type of exercises do you do?"
                variant="outlined"
                margin="dense"
              />
            )}
          />
          <Controller
            name="exercise.exerciseDuration"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="How long do you exercise?"
                variant="outlined"
                margin="dense"
              />
            )}
          />
        </>
      )}
    </Box>
  );
};

export default ExerciseBox;
