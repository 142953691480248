import { Controller, useForm } from "react-hook-form";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

type DietBoxProps = {
  control: any;
  watch: any;
};

const DietBox: React.FC<DietBoxProps> = ({ control, watch }) => {
  return (
    <Box mt={3} mb={3}>
      <Typography variant="h6">Diet and Appetite</Typography>
      <Controller
        name="diet.appetite"
        control={control}
        defaultValue={"satisfied"}
        render={({ field }) => (
          <FormControl margin="dense" sx={{ minWidth: 175 }}>
            <InputLabel>Appetite</InputLabel>
            <Select {...field} label="Sexual Partners" sx={{ minWidth: 175 }}>
              <MenuItem value="always hungry">Always Hungry</MenuItem>
              <MenuItem value="satisfied">Satisfied with Intake</MenuItem>
              <MenuItem value="low">Low Appetite</MenuItem>
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name="diet.nutrition"
        control={control}
        defaultValue={"poor"}
        render={({ field }) => (
          <FormControl margin="dense" sx={{ minWidth: 175 }}>
            <InputLabel>Nutrition</InputLabel>
            <Select {...field} label="Nutrition" sx={{ minWidth: 175 }}>
              <MenuItem value="poor">Poor</MenuItem>
              <MenuItem value="fair">Fair</MenuItem>
              <MenuItem value="nutritious">Nutritious</MenuItem>
            </Select>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default DietBox;
