import { QuestionsData } from "../types";

const defaultOptions = ["None", "Mild", "Moderate", "Severe", "Extreme"];

export const questions: QuestionsData = {
  title:
    "LEVEL 2—Repetitive Thoughts and Behaviors—Adult, *Adapted from the Florida Obsessive-Compulsive Inventory (FOCI) Severity Scale (Part B)",
  formName: "cc_level2_ocd",
  instructions:
    "On the DSM-5-TR Level 1 cross-cutting questionnaire that you just completed, you indicated that during the past 2 weeks you have been bothered by “unwanted repeated thoughts, images, or urges” and/or “being driven to perform certain behaviors or mental acts over and over” at a mild or greater level of severity. The questions below ask about these feelings in more detail and especially how often you have been bothered by a list of symptoms during the past 7 days. Please respond to each item by marking ( or x) one box per row.",
  sections: [
    {
      sectionId: "section0",
      sectionNumeral: "I",
      label: "Repetitive Thoughts and Behaviors",
      score: 0,
      scoreThresholds: [
        { minimum: 0, maximum: 5, evaluation: "None" },
        { minimum: 6, maximum: 10, evaluation: "Mild" },
        { minimum: 11, maximum: 15, evaluation: "Moderate" },
        { minimum: 16, maximum: 20, evaluation: "Severe" },
      ],
      scoreExplanation: "Sum of all answers with (0-4 points) for each.",
      instructions: "In the past SEVEN (7) DAYS....",
      questions: [
        {
          question:
            "On average, how much time is occupied by these thoughts or behaviors each day?",
          options: [
            "N/A",
            "Less than an hour a day",
            "1 to 3 hours a day",
            "3 to 8 hours a day",
            "More than 8 hours a day",
          ],
          response: 0,
          type: "toggle_group",
        },
        {
          question:
            "How much distress do these thoughts or behaviors cause you?",
          response: 0,
          options: [
            "N/A",
            "slightly disturbing",
            "disturbing but still manageable",
            "very disturbing",
            "overwhelming distress",
          ],
          type: "toggle_group",
        },
        {
          question:
            "How hard is it for you to control these thoughts or behaviors?",
          options: [
            "Complete control",
            "Much control (usually able to control thoughts or behaviors)",
            "Moderate control (sometimes able to control thoughts or behaviors)",
            "Little control (infrequently able to control thoughts or behaviors)",
            "No control (unable to control thoughts or behaviors)",
          ],
          response: 0,
          type: "toggle_group",
        },
        {
          question:
            "How much do these thoughts or behaviors cause you to avoid doing anything, going anyplace, or being with anyone?",
          options: [
            "No avoidance",
            "Mild (occasional avoidance)",
            "Moderate (regularly avoid doing these things)",
            "Severe (frequent and extensive avoidance)",
            "Extreme (nearly complete avoidance; house-bound)",
          ],
          response: 0,
          type: "toggle_group",
        },
        {
          question:
            "How much do these thoughts or behaviors interfere with school, work, or your social or family life?",
          options: [
            "None",
            "Mild (slight interference)",
            "Moderate (definite interference with functioning, but still manageable)",
            "Severe (substantial interference)",
            "Extreme (near-total interference; incapacitated)",
          ],
          response: 0,
          type: "toggle_group",
        },
      ],
    },
  ],
  scoring: {
    total_partial_raw_score: 0, //'Enter total/partial raw score',
    prorated_total_raw_score: 0,
    // 'Enter prorated total raw score (if 1 item is left unanswered)',
    average_total_score: 0, // 'Enter average total score'
  },
  disclaimer:
    "© 1994 Wayne K. Goodman, MD and Eric Storch, PhD. This material can be reproduced without permission by clinicians for use with their own patients. Any other use, including electronic use, requires written permission from Dr. Goodman (wkgood@gmail.com)",
};
