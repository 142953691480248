import { QuestionsData } from "../types";

const defaultOptions = [
  "Not at all",
  "Several days",
  "More than half the days",
  "Nearly every day",
];

const qs = [
  "Feeling nervous, anxious, or on edge",
  "Not being able to stop or control worrying",
  "Worrying too much about different things",
  "Trouble relaxing",
  "Being so restless that it is hard to sit still",
  "Becoming easily annoyed or irritable",
  "Feeling afraid, as if something awful might happen",
  "If you checked off any problems, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?",
];

export const questions: QuestionsData = {
  title:
    "Primary Care Evaluation of Mental Disorders Patient Health Questionnaire (PRIME-MD-PHQ)",
  formName: "gad7",
  instructions:
    "Over the last two weeks, how often have you been bothered by the following problems?",
  sections: [
    {
      sectionId: "section0",
      label: "Anxiety",
      sectionNumeral: "I",
      score: 0,
      scoreThresholds: [
        { minimum: 0, maximum: 4, evaluation: "None" },
        { minimum: 5, maximum: 9, evaluation: "Mild" },
        { minimum: 10, maximum: 14, evaluation: "Moderate" },
        { minimum: 15, maximum: 21, evaluation: "Severe" },
      ],
      scoreExplanation:
        "Sum of all answers with (0=never; 1=rarely; 2=sometimes; 3=often; and 4=always",
      instructions: "In the past two weeks....",
      questions: [
        {
          question: qs[0],
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: qs[1],
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: qs[2],
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: qs[3],
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: qs[4],
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: qs[5],
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: qs[6],
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: qs[7],
          options: [
            "Not difficult at all",
            "Somewhat difficult",
            "Very difficult",
            "Extremely difficult",
          ],
          response: 0,
          type: "toggle_group",
        },
      ],
    },
  ],
  scores: [
    {
      label: "Total/Partial Raw Score",
      type: "number",
      value: 0,
    },
    {
      label: "Prorated Total Raw Score",
      type: "number",
      value: 0,
    },
    {
      label: "T-Score",
      type: "number",
      value: 0,
    },
  ],
  disclaimer:
    "©2008-2012 PROMIS Health Organization (PHO) and PROMIS Cooperative Group. This material can be reproduced without permission by clinicians for use with their patients. Any other use, including electronic use, requires written permission of the PHO.",
};
