import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";

type SafetyBoxProps = {
  control: any;
  watch: any;
  resetField: any;
};

const SafetyBox: React.FC<SafetyBoxProps> = ({
  control,
  watch,
  resetField,
}) => {
  const guns = watch("safety.guns");

  useEffect(() => {
    if (!guns) {
      resetField("safety.gunsLocked");
    }
  }, [guns, resetField]);

  return (
    <Box mt={3} mb={3}>
      <Typography variant="h6">Safety</Typography>
      <Controller
        name="safety.home"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Is there anything at home, school, or work that makes you feel unsafe/threatened?"
            />
          </FormControl>
        )}
      />
      <Controller
        name="safety.guns"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Are there guns in the home?"
            />
          </FormControl>
        )}
      />
      {guns && (
        <>
          <Controller
            name="safety.gunsLocked"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <FormControl fullWidth margin="dense">
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Are the guns locked up?"
                />
              </FormControl>
            )}
          />
        </>
      )}
      <Controller
        name="safety.storeMedications"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            multiline
            label="How do you store your medications at home?"
            variant="outlined"
            margin="dense"
          />
        )}
      />
      <Controller
        name="safety.violence"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Is violence at home a concern for you?"
            />
          </FormControl>
        )}
      />
      <Controller
        name="safety.drugs"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Is there drug/substance use in your home environment?"
            />
          </FormControl>
        )}
      />
      <Controller
        name="safety.selfHarm"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Do you have any thoughts of hurting yourself?"
            />
          </FormControl>
        )}
      />
      <Controller
        name="safety.elseHarm"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Do you have any thoughts of hurting others?"
            />
          </FormControl>
        )}
      />
    </Box>
  );
};

export default SafetyBox;
