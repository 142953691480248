import { getQuestionsJson, parseDBQuestionsData } from "../utils/dbUtils";
import { useEffect, useState } from "react";
import { QuestionsData, SectionResponse } from "../types";
import { updateScores } from "../utils/formScoringUtils";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

const useQuestionsData = (formName: string) => {
  const [questionsData, setQuestionsData] = useState<QuestionsData>(
    {} as QuestionsData
  );

  const { forms: data } = useSelector((state: RootState) => state.form);
  console.log("redux state in useQuestionsData", data);

  useEffect(() => {
    if (data) {
      const sectionResponseArray: SectionResponse[] = data[formName] || [];

      const initQuestionsData = getQuestionsJson(formName);
      const modifiedQuestionsData = updateScores(
        sectionResponseArray,
        initQuestionsData
      );
      setQuestionsData(modifiedQuestionsData);
    } else {
      const initQuestionsData = getQuestionsJson(formName);
      setQuestionsData(initQuestionsData);
    }
  }, [data, formName]);

  return { questionsData };
};

export default useQuestionsData;
