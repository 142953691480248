import { QuestionsData } from "../types";

export const questions: QuestionsData = {
  title: "Mood Disorder Questionnaire (MDQ)",
  instructions:
    "Check the answer that best applies to you. Please answer each question as best you can.",
  formName: "mdq",
  sections: [
    {
      sectionId: "section0",
      sectionNumeral: "I",
      label: "Section 1",
      score: 0,
      scoreThresholds: [],
      scoreExplanation:
        "If you have answered YES to seven (7) or more of the above questions, and have experienced several of the indicated events as marked with an asterisk (*) in question 1, this may indicate that you have a mood disorder. Please print out the results and share them with your healthcare professional.",
      instructions:
        "Has there ever been a period of time when you were not your usual self and...",
      questions: [
        {
          question:
            "...you were so irritable that you shouted at people or started fights or arguments?",
          response: 0,
          type: "toggle_group",
          options: ["Yes", "No"],
        },
        {
          question: "...you felt much more self-confident than usual?",
          response: 0,
          type: "toggle_group",
          options: ["Yes", "No"],
        },
        {
          question:
            "...you got much less sleep than usual and found that you didn't really miss it?",
          response: 0,
          type: "toggle_group",
          options: ["Yes", "No"],
        },
        {
          question:
            "...you were much more talkative or spoke much faster than usual?",
          response: 0,
          type: "toggle_group",
          options: ["Yes", "No"],
        },
        {
          question:
            "...thoughts raced through your head or you couldn't slow your mind down?",
          response: 0,
          type: "toggle_group",
          options: ["Yes", "No"],
        },
        {
          question:
            "...you were so easily distracted by things around you that you had trouble concentrating or staying on track?",
          response: 0,
          type: "toggle_group",
          options: ["Yes", " No"],
        },
      ],
    },
  ],
};
