import { QuestionsData } from "../types";

const defaultOptions = [
  "Not at all",
  "A little bit",
  "Somewhat",
  "Quite a bit",
  "Very much",
];
export const questions: QuestionsData = {
  title: "LEVEL 2—Sleep Disturbance—Adult",
  formName: "cc_level2_sleep",
  subtitle: "*PROMIS—Sleep Disturbance—Short Form",
  instructions:
    "On the DSM-5-TR Level 1 cross-cutting questionnaire that you just completed, you indicated that during the past 2 weeks you (the individual receiving care) have been bothered by “problems with sleep that affected your sleep quality overall” at a mild or greater level of severity. The questions below ask about these feelings in more detail and especially how often you (the individual receiving care) have been bothered by a list of symptoms during the past 7 days. Please respond to each item by marking ( or x) one box per row.",
  sections: [
    {
      sectionId: "section0",
      sectionNumeral: "I",
      score: 0,
      scoreThresholds: [
        { minimum: 0, maximum: 25, evaluation: "None to slight" },
        { minimum: 26, maximum: 29, evaluation: "Mild" },
        { minimum: 30, maximum: 37, evaluation: "Moderate" },
        { minimum: 38, maximum: 40, evaluation: "Severe" },
      ],
      scoreExplanation:
        "Sum of all answers with (1=Not at all; 2=A little bit; 3=Somewhat; 4=Quite a bit; 5=Very much)",
      instructions: "In the past SEVEN (7) DAYS....",
      label: "Sleep Disturbance",
      questions: [
        {
          question: "My sleep was restless.",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "I was satisfied with my sleep.",
          response: 0,
          options: defaultOptions,
          type: "toggle_group",
        },
        {
          question: "My sleep was refreshing.",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "I had difficulty falling asleep.",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "I had trouble staying asleep.",
          customLabels: ["Never", "Rarely", "Sometimes", "Often", "Always"],
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "I had trouble sleeping.",
          customLabels: ["Never", "Rarely", "Sometimes", "Often", "Always"],
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "I got enough sleep.",
          customLabels: ["Always", "Often", "Sometimes", "Rarely", "Never"],
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "My sleep quality was...",
          customLabels: ["Very Poor", "Poor", "Fair", "Good", "Very good"],
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
      ],
    },
  ],
  disclaimer:
    "©2008-2012 PROMIS Health Organization (PHO) and PROMIS Cooperative Group. This material can be reproduced without permission by clinicians for use with their patients. Any other use, including electronic use, requires written permission of the PHO.",
};
