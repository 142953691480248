import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState, FormsState, Information, SectionResponse } from "../types";
import { PatientIntakeFormValues } from "../pages/PatientIntake";

const initialState: AppState = {
  info: {} as Information,
  forms: {},
  patientIntake: {} as PatientIntakeFormValues,
  requiredPages: [],
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    loadInitialData: (
      state,
      action: PayloadAction<{
        info: Information;
        patientIntake: PatientIntakeFormValues;
      }>
    ) => {
      state.info = action.payload.info;
      state.patientIntake = action.payload.patientIntake;
    },
    loadFormData: (
      state,
      action: PayloadAction<{ formName: string; sections: SectionResponse[] }>
    ) => {
      state.forms[action.payload.formName] = action.payload.sections;
    },
    updateFormRedux: (state, action: PayloadAction<{ forms: FormsState }>) => {
      state.forms = action.payload.forms;
    },
    resetAllFormData: (state) => {
      // reset all data in forms but retain the info field
      return {
        ...initialState,
        info: state.info,
      };
    },
    resetInfo: (state) => {
      state.info = {} as Information;
    },
    updateInfo: (state, action: PayloadAction<{ info: Information }>) => {
      state.info = action.payload.info;
    },
    resetFormData: (state, action: PayloadAction<{ formName: string }>) => {
      state.forms[action.payload.formName] = [];
    },
    resetPatientIntakeData: (state) => {
      state.patientIntake = {} as PatientIntakeFormValues;
    },
    savePatientIntakeData: (
      state,
      action: PayloadAction<{ data: PatientIntakeFormValues }>
    ) => {
      state.patientIntake = action.payload.data;
      if (
        action.payload.data?.personalHistory_medical?.includes("depression")
      ) {
        state.requiredPages.push("phq9");
      } else {
        state.requiredPages = state.requiredPages.filter(
          (page) => page !== "phq9"
        );
      }
      if (action.payload.data?.personalHistory_medical?.includes("anxiety")) {
        state.requiredPages.push("gad7");
      } else {
        state.requiredPages = state.requiredPages.filter(
          (page) => page !== "gad7"
        );
      }
      if (action.payload.data?.personalHistory_medical?.includes("adhd")) {
        state.requiredPages.push("asrs");
      } else {
        state.requiredPages = state.requiredPages.filter(
          (page) => page !== "asrs"
        );
      }
      if (action.payload.data?.personalHistory_medical?.includes("bipolar")) {
        state.requiredPages.push("mdq");
      } else {
        state.requiredPages = state.requiredPages.filter(
          (page) => page !== "mdq"
        );
      }
    },
  },
});

export const {
  loadInitialData,
  loadFormData,
  resetAllFormData,
  resetPatientIntakeData,
  savePatientIntakeData,
  updateFormRedux,
  resetInfo,
  updateInfo,
  resetFormData,
} = formSlice.actions;
export default formSlice.reducer;
