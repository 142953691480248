import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  fetchSignInMethodsForEmail,
} from "firebase/auth";

import { auth } from "../firebaseConfig";

const SignupPage: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [proxyName, setProxyName] = useState("");
  const [age, setAge] = useState<number | string>("");
  const [relationship, setRelationship] = useState("");
  const [time, setTime] = useState<number | string>("");
  const [isProxy, setIsProxy] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!name || !age || !email) {
      setError("Name, age, and email are required");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    const signInMethods = await fetchSignInMethodsForEmail(auth, email);
    console.log("signInMethods", signInMethods);
    if (signInMethods.length > 0) {
      setError("This email is already in use. Please try another email.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const userId = user.uid;

      await sendEmailVerification(user);

      const body = {
        proxy_name: proxyName,
        name,
        email,
        age: Number(age),
        relationship,
        isProxy,
        time,
      };
      console.log("registration no longer supported");
      setError("Registration no longer supported");
      return;

      // registerUser(userId, body);

      // // setSuccess("User created successfully");
      // setName("");
      // setProxyName("");
      // setAge("");
      // setRelationship("");
      // setTime("");
      // setError(null);
      // setSuccess(
      //   "User created successfully!  Check your email to verify your account."
      // );
      // setOpenSnackbar(true);
      // navigate("/patient-intake");
    } catch (error: any) {
      if (error.code === "auth/email-already-in-use") {
        setError("This email is already in use. Please try another email.");
      } else {
        setError("Failed to create user. Please try again.");
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          {isProxy && (
            <TextField
              margin="normal"
              required
              fullWidth
              id="proxy_name"
              label="Name"
              name="proxy_name"
              autoComplete="name"
              autoFocus
              value={proxyName}
              onChange={(e) => setProxyName(e.target.value)}
            />
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label={isProxy ? "Patient Name" : "Name"}
            name="name"
            autoComplete="name"
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="age"
            label={isProxy ? "Patient Age" : "Age"}
            type="number"
            id="age"
            value={age}
            onChange={(e) => setAge(e.target.value)}
          />
          {isProxy && (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                name="relationship"
                label="Relationship to Patient"
                type="text"
                id="relationship"
                value={relationship}
                onChange={(e) => setRelationship(e.target.value)}
              />

              <TextField
                margin="normal"
                label={"Time spent with patient in a typical week (hrs)"}
                name="time"
                type="number"
                required
                value={time}
                onChange={(e) => setTime(e.target.value)}
                fullWidth
              />
            </>
          )}

          <TextField
            margin="normal"
            required
            fullWidth
            name="email"
            label="Email Address"
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirm_password"
            label="Confirm Password"
            type="password"
            id="confirm_password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={isProxy}
                onChange={(e) => setIsProxy(e.target.checked)}
              />
            }
            label="I am completing these forms for someone else"
          />
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SignupPage;
