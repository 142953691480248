import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";

type SubstanceUseQuestionsProps = {
  control: any;
  watch: any;
};

const SubstanceUseBox: React.FC<SubstanceUseQuestionsProps> = ({
  control,
  watch,
}) => {
  const alcoholUse = watch("substanceUse.alcohol");
  const tobaccoUse = watch("substanceUse.tobacco");
  const vapeUse = watch("substanceUse.vape");
  const otherDrugs = watch("substanceUse.otherDrugs");
  return (
    <Box mt={3} mb={3}>
      <Typography variant="h6">Substance Use</Typography>
      <Controller
        name="substanceUse.alcohol"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Do you drink alcohol?"
            />
          </FormControl>
        )}
      />
      {alcoholUse && (
        <>
          <Controller
            name="substanceUse.alcoholFrequency"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <TextField
                {...field}
                label="Alcohol Frequency"
                variant="outlined"
                margin="dense"
              />
            )}
          ></Controller>
          <Controller
            name="substanceUse.alcoholAmount"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <TextField
                {...field}
                label="Alcohol Amount"
                variant="outlined"
                margin="dense"
              />
            )}
          ></Controller>
        </>
      )}
      <Controller
        name="substanceUse.tobacco"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Do you smoke cigarettes?"
            />
          </FormControl>
        )}
      />
      {tobaccoUse && (
        <>
          <Controller
            name="substanceUse.tobaccoPerDay"
            control={control}
            defaultValue={0}
            render={({ field }) => (
              <TextField
                {...field}
                label="Cigarettes per day"
                variant="outlined"
                margin="dense"
              />
            )}
          ></Controller>
          <Controller
            name="substanceUse.tobaccoYears"
            control={control}
            defaultValue={0}
            render={({ field }) => (
              <TextField
                {...field}
                label="How many years have you smoked?"
                variant="outlined"
                margin="dense"
              />
            )}
          ></Controller>
        </>
      )}
      <Controller
        name="substanceUse.vape"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Do you vape?"
            />
          </FormControl>
        )}
      />
      {vapeUse && (
        <>
          <Controller
            name="substanceUse.vapePerDay"
            control={control}
            defaultValue={0}
            render={({ field }) => (
              <TextField
                {...field}
                label="How often per day?"
                variant="outlined"
                margin="dense"
              />
            )}
          ></Controller>
          <Controller
            name="substanceUse.vapeYears"
            control={control}
            defaultValue={0}
            render={({ field }) => (
              <TextField
                {...field}
                label="How many years have you vaped?"
                variant="outlined"
                margin="dense"
              />
            )}
          ></Controller>
        </>
      )}
      <Controller
        name="substanceUse.otherDrugs"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Do you use any other substances?"
            />
          </FormControl>
        )}
      />
      {otherDrugs && (
        <>
          <Controller
            name="substanceUse.otherDrugsList"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <TextField
                {...field}
                label="Other Drugs"
                variant="outlined"
                margin="dense"
              />
            )}
          ></Controller>
          <Controller
            name="substanceUse.otherDrugsHowOften"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <TextField
                {...field}
                label="How often?"
                variant="outlined"
                margin="dense"
              />
            )}
          ></Controller>
        </>
      )}
      <Controller
        name="substanceUse.usedNeedles"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Have you ever used needles to inject drugs?"
            />
          </FormControl>
        )}
      />
      <Controller
        name="substanceUse.usedOthersDrugs"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Have you ever used someone else's drugs?"
            />
          </FormControl>
        )}
      />
      {(tobaccoUse || vapeUse || otherDrugs) && (
        <Controller
          name="substanceUse.consideredQuitting"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <FormControl fullWidth margin="dense">
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label="Have you considered quitting?"
              />
            </FormControl>
          )}
        />
      )}
    </Box>
  );
};

export default SubstanceUseBox;
