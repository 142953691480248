import { QuestionsData } from "../types";

const defaultOptions = [
  "Not at all",
  "Slight or rare, less than a day or two",
  "Mild or several days",
  "Moderate or more than half the days",
  "Severe or nearly every day",
];

export const questions: QuestionsData = {
  title: "LEVEL 2—Mania—Adult",
  formName: "cc_level2_mania",
  subtitle: "* Altman Self-Rating Mania Scale (ASRM)",
  instructions:
    "Please answer the following questions based on your experiences over the past TWO (2) WEEKS. Select the statement that best describes your experience for each question.",
  sections: [
    {
      sectionId: "section0",
      label: "Mood and Behavior",
      sectionNumeral: "I",
      score: 0,
      scoreThresholds: [
        {
          minimum: 0,
          maximum: 5,
          evaluation:
            "Less likely to be associated with significant symptoms of mania",
        },
        {
          minimum: 6,
          maximum: 25,
          evaluation:
            "Indicates a high probability of a manic or hypomanic condition. May indicate a need for treatment and/or further diagnostic workup",
        },
      ],
      questions: [
        {
          question:
            "How often do you feel happier or more cheerful than usual?",
          type: "radio",
          options: [
            "I do not feel happier or more cheerful than usual.",
            "I occasionally feel happier or more cheerful than usual.",
            "I often feel happier or more cheerful than usual.",
            "I feel happier or more cheerful than usual most of the time.",
            "I feel happier or more cheerful than usual all of the time.",
          ],
          response: 0,
        },
        {
          question: "How often do you feel more self-confident than usual?",
          options: [
            "I do not feel more self-confident than usual.",
            "I occasionally feel more self-confident than usual.",
            "I often feel more self-confident than usual.",
            "I frequently feel more self-confident than usual.",
            "I feel extremely self-confident all of the time.",
          ],
          response: 0,
          type: "radio",
        },
        {
          question: "How often do you need less sleep than usual?",
          options: [
            "I do not need less sleep than usual.",
            "I occasionally need less sleep than usual.",
            "I often need less sleep than usual.",
            "I frequently need less sleep than usual.",
            "I can go all day and all night without any sleep and still not feel tired.",
          ],
          response: 0,
          type: "radio",
        },
        {
          question: "How often do you talk more than usual?",
          options: [
            "I do not talk more than usual.",
            "I occasionally talk more than usual.",
            "I often talk more than usual.",
            "I frequently talk more than usual.",
            "I talk constantly and cannot be interrupted.",
          ],
          response: 0,
          type: "radio",
        },
        {
          question:
            "How often have you been more active than usual (socially, sexually, at work, home, or school)?",
          options: [
            "I have not been more active (either socially, sexually, at work, home, or school) than usual.",
            "I have occasionally been more active than usual.",
            "I have often been more active than usual.",
            "I have frequently been more active than usual.",
            "I am constantly more active or on the go all the time.",
          ],
          response: 0,
          type: "radio",
        },
      ],
    },
  ],
  disclaimer:
    "Reprinted from Altman EG, Hedeker D, Peterson JL, Davis JM: The Altman Self-Rating Mania Scale. Biological Psychiatry 42: 948-955, 1997. Copyright © 1997, with permission from Elsevier.",
};
