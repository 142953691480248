import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { styled } from "@mui/system";

interface StyledToggleButtonGroupProps {
  isMobile: boolean;
}

const StyledToggleButtonGroup = styled(
  (props: StyledToggleButtonGroupProps & any) => (
    <ToggleButtonGroup {...props} />
  )
)(({ theme, isMobile, optionsCount }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: isMobile ? "space-between" : "flex-start",
  "& .MuiToggleButton-root": {
    flex: isMobile ? "1 1 auto" : `0 1 calc(100% / ${optionsCount} - 8px)`,
    margin: "4px -1px",
    boxSizing: "border-box",
    minWidth: isMobile ? "auto" : `calc(100% / ${optionsCount} - 8px)`,
    fontSize: "0.8rem",
    textAlign: "center",
    whiteSpace: isMobile ? "nowrap" : "normal",
    overflow: "hidden",
    border: "1px solid",
    borderRadius: "4px",
    textOverflow: "ellipsis",
    "&.Mui-selected": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      boxShadow: theme.shadows[4],
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: "#5e9fdf", // Add your hover color here
        borderColor: theme.palette.primary.main,
      },
    },
  },
}));

type ToggleGroupQuestionProps = {
  control: any;
  name: string;
  question: string;
  options?: string[];
  response: number | string | Date | boolean | string[][];
};

const ToggleGroupQuestion: React.FC<ToggleGroupQuestionProps> = ({
  control,
  name,
  question,
  options,
  response,
}) => {
  const [renderToggle, setRenderToggle] = React.useState(false);
  const { setValue, watch } = useFormContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const optionsCount = options ? options.length : 1;

  useEffect(() => {
    if (typeof response === "number") {
      setValue(name, response);
    }
    setRenderToggle(!renderToggle);
  }, [response, setValue, name]);

  const watchedValue = watch(name);
  return (
    <div style={{ backgroundColor: "white", margin: 0 }}>
      <Typography variant="body1" style={{ marginBottom: "10px" }}>
        {question}
      </Typography>
      <Controller
        name={name}
        control={control}
        defaultValue={response as number}
        render={({ field }) => (
          <StyledToggleButtonGroup
            key={`${name}-group-${response}`}
            exclusive
            {...field}
            value={field.value === undefined ? watchedValue : field.value}
            onChange={(event: React.MouseEvent<HTMLElement>, newValue: any) =>
              field.onChange(newValue)
            }
            aria-label="likert scale"
            size="small"
            isMobile={isMobile}
            optionsCount={optionsCount}
          >
            {options &&
              options.map((option, idx) => (
                <ToggleButton
                  key={option}
                  value={idx} // Store the index as the value
                  aria-label={`option-${option}`}
                >
                  {option}
                </ToggleButton>
              ))}
          </StyledToggleButtonGroup>
        )}
      />
    </div>
  );
};

export default ToggleGroupQuestion;
