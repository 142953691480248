import { QuestionsData } from "../types";

const defaultOptions = [
  "Not at all",
  "Slight or rare, less than a day or two",
  "Mild or several days",
  "Moderate or more than half the days",
  "Severe or nearly every day",
];

export const questions: QuestionsData = {
  title: "PROMIS Emotional Distress—Anger—Short Form",
  formName: "cc_level2_anger",
  instructions:
    "On the DSM-5-TR Level 1 cross-cutting questionnaire that you just completed, you indicated that during the past 2 weeks you (the individual receiving care) have been bothered by “feeling irritated, grouchy, or angry” at a mild or greater level of severity. The questions below ask about these feelings in more detail and especially how often you (the individual receiving care) have been bothered by a list of symptoms during the past 7 days. Please respond to each item by clicking below.",
  sections: [
    {
      sectionId: "section0",
      label: "Anger",
      sectionNumeral: "I",
      score: 0,
      scoreThresholds: [
        { minimum: 0, maximum: 13, evaluation: "None to slight" },
        { minimum: 14, maximum: 15, evaluation: "Mild" },
        { minimum: 16, maximum: 20, evaluation: "Moderate" },
        { minimum: 21, maximum: 25, evaluation: "Severe" },
      ], // T-scores converted to raw score, assuming all questions answered
      scoreExplanation:
        "Sum of all answers with (1=never; 2=rarely; 3=sometimes; 4=often; and 5=always)",
      instructions: "In the past SEVEN (7) DAYS....",
      questions: [
        {
          question: "I was irritated more than people knew.",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "I felt angry.",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "I felt like I was ready to explode.",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "I was grouchy.",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "I felt annoyed.",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
      ],
    },
  ],
  disclaimer:
    "©2008-2012 PROMIS Health Organization (PHO) and PROMIS Cooperative Group. This material can be reproduced without permission by clinicians for use with their patients. Any other use, including electronic use, requires written permission of the PHO.",
};
