import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Box,
  Divider,
  Menu,
  MenuItem,
  ListItemButton,
  useMediaQuery,
  Theme,
  IconButton,
  Container,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import HomeIcon from "@mui/icons-material/Home";
import LoginIcon from "@mui/icons-material/Login";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MenuIcon from "@mui/icons-material/Menu";

import { Link, useLocation, useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { signOut } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { useAuth } from "../hooks/useAuth";
import useQuestionsData from "../hooks/useQuestionsData";
import { level2_form_required } from "../utils/formScoringUtils";
import { formsList } from "../utils/dbUtils";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { PatientIntakeFormValues } from "../pages/PatientIntake";
import { ExitToApp, Help, History, ListAlt, Person } from "@mui/icons-material";
import { SnackbarProvider } from "../utils/SnackbarContext";

const drawerWidth = 240;

const Root = styled(Box)`
  display: flex;
`;

const AppBarStyled = styled(AppBar)`
  z-index: ${(props) => props.theme.zIndex.drawer + 1};
`;

const DrawerContainer = styled.div`
  overflow: auto;
`;

const Content = styled(Container)`
  flex-grow: 1;
  max-width: false;
  padding: ${(props) => props.theme.spacing(0)};
`;

const ToolbarStyled = styled(Toolbar)`
  ${(props) => props.theme.mixins.toolbar};
`;

const AvatarStyled = styled(Avatar)`
  margin-left: auto;
`;

type LayoutProps = {
  children: React.ReactNode;
};

type FormLink = {
  text: string;
  href: string;
  icon?: React.ReactNode;
  form?: string;
};

const linkMap: FormLink[] = [
  {
    text: "Home",
    href: "/",
    icon: <HomeIcon />,
    form: "N/A",
  },
  {
    text: "Patient Intake",
    href: "/patient-intake",
    icon: <ContentPasteIcon />,
    form: "patientIntake",
  },
  {
    text: "Level 1 Form",
    href: "/cross-cutting-level1",
    icon: <ContentPasteIcon />,
    form: "cc_level1",
  },
  {
    text: "Anger Form",
    href: "/cross-cutting-level2-anger",
    icon: <ContentPasteIcon />,
    form: "cc_level2_anger",
  },
  {
    text: "Anxiety Form",
    href: "/cross-cutting-level2-anxiety",
    icon: <ContentPasteIcon />,
    form: "cc_level2_anxiety",
  },
  {
    text: "Depression Form",
    href: "/cross-cutting-level2-depression",
    icon: <ContentPasteIcon />,
    form: "cc_level2_depression",
  },
  {
    text: "Mania Form",
    href: "/cross-cutting-level2-mania",
    icon: <ContentPasteIcon />,
    form: "cc_level2_mania",
  },
  {
    text: "OCD Form",
    href: "/cross-cutting-level2-ocd",
    icon: <ContentPasteIcon />,
    form: "cc_level2_ocd",
  },
  {
    text: "Sleep Form",
    href: "/cross-cutting-level2-sleep",
    icon: <ContentPasteIcon />,
    form: "cc_level2_sleep",
  },
  {
    text: "Somatic Symptoms Form",
    href: "/cross-cutting-level2-somatic",
    icon: <ContentPasteIcon />,
    form: "cc_level2_somatic",
  },
  {
    text: "Gad 7 Anxiety",
    href: "/gad7",
    icon: <ContentPasteIcon />,
    form: "gad7",
  },
  {
    text: "ASRS",
    href: "/asrs",
    icon: <ContentPasteIcon />,
    form: "asrs",
  },
  {
    text: "PHQ-9",
    href: "/phq9",
    icon: <ContentPasteIcon />,
    form: "phq9",
  },
  {
    text: "MDQ",
    href: "/mdq",
    icon: <ContentPasteIcon />,
    form: "mdq",
  },
];

const linkMapStatic = [
  { text: "Sign in", href: "/signin", icon: <LoginIcon />, signedIn: false },
  { text: "Signup", href: "/signup", icon: <HowToRegIcon />, signedIn: false },
  {
    text: "Settings",
    href: "/settings",
    icon: <SettingsIcon />,
    signedIn: true,
  },
];

const showPage = (
  form: string,
  questionsData: any,
  intake: PatientIntakeFormValues
) => {
  if (form === "cc_level1" || form === "patientIntake") {
    return true;
  }

  switch (form) {
    case "cc_level2_anger":
    case "cc_level2_anxiety":
    case "cc_level2_depression":
    case "cc_level2_mania":
    case "cc_level2_ocd":
    case "cc_level2_sleep":
    case "cc_level2_somatic":
      return level2_form_required(questionsData, form);
    case "gad7":
      return (
        intake?.personalHistory_medical?.includes("anxiety") ||
        intake?.familyHistory?.anxiety?.length > 0 ||
        false
      );
    case "asrs":
      return (
        intake?.personalHistory_medical?.includes("adhd") ||
        intake?.familyHistory?.adhd?.length > 0 ||
        false
      );
    case "phq9":
      return (
        intake?.personalHistory_medical?.includes("depression") ||
        intake?.familyHistory?.depression?.length > 0 ||
        false
      );
    case "mdq":
      return (
        intake?.personalHistory_medical?.includes("bipolar") ||
        intake?.familyHistory?.bipolar?.length > 0 ||
        false
      );
    default:
      return false;
  }
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const intake: PatientIntakeFormValues = useSelector(
    (state: RootState) => state.form.patientIntake
  );
  const { forms } = useSelector((state: RootState) => state.form.forms);

  const navigate = useNavigate();

  const { questionsData } = useQuestionsData("cc_level1");

  const showSendForms =
    forms &&
    formsList.every(
      (form) =>
        (level2_form_required(questionsData, form) &&
          Object.keys(forms).includes(form)) ||
        form === "cc_level1" ||
        !level2_form_required(questionsData, form)
    );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    console.log("clicked", event);
    setAnchorEl(event.currentTarget);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickSettings = () => {
    navigate("/settings");
  };

  const onClickLogout = async () => {
    try {
      await signOut(auth);
      navigate("/signin"); // Redirect to the login page
    } catch (error) {
      // Handle errors, if any
      console.error("Error signing out:", error);
    }
  };

  const location = useLocation();

  const menu = (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem onClick={onClickSettings}>Settings</MenuItem>
      <MenuItem onClick={onClickLogout}>Logout</MenuItem>
    </Menu>
  );

  // const signedOutMenu = (
  //   <Menu
  //     id="basic-menu"
  //     anchorEl={anchorEl}
  //     open={open}
  //     onClose={handleClose}
  //     MenuListProps={{
  //       "aria-labelledby": "basic-button",
  //     }}
  //   >
  //     <MenuItem onClick={() => navigate("/signin")}>Sign in</MenuItem>
  //     <MenuItem onClick={() => navigate("/signup")}>Sign up</MenuItem>
  //   </Menu>
  // );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="h6" noWrap>
          Intake Portal
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        <ListItem button onClick={() => handleNavigation("/")}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button onClick={() => handleNavigation("/checklist")}>
          <ListItemIcon>
            <ContentPasteIcon />
          </ListItemIcon>
          <ListItemText primary="Checklist" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => handleNavigation("/help")}>
          <ListItemIcon>
            <Help />
          </ListItemIcon>
          <ListItemText primary="FAQ" />
        </ListItem>
        {/* <ListItem button onClick={() => handleNavigation("/settings")}>
          <ListItemIcon>
            <Help />
          </ListItemIcon>
          <ListItemText primary="Dev Tools" />
        </ListItem> */}
        {/* <ListItem button onClick={onClickLogout}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem> */}
      </List>
    </div>
  );

  // const loggedOutDrawer = (
  //   <div>
  //     <ToolbarStyled />
  //     <DrawerContainer>
  //       <List>
  //         {linkMapStatic.map(({ text, href, icon, signedIn }) => {
  //           if (signedIn === Boolean(user)) {
  //             return (
  //               <ListItem button key={text} component={Link} to={href}>
  //                 <ListItemIcon>{icon}</ListItemIcon>
  //                 <ListItemText primary={text} />
  //               </ListItem>
  //             );
  //           }
  //         })}
  //       </List>
  //     </DrawerContainer>
  //   </div>
  // );

  // const oldDrawer = (
  //   <div>
  //     <ToolbarStyled />
  //     <DrawerContainer>
  //       <List>
  //         {user &&
  //           linkMap.map(({ text, href, form }) => {
  //             if (showPage(form || "", questionsData, intake)) {
  //               const complete =
  //                 forms &&
  //                 typeof form === "string" &&
  //                 Object.keys(forms).includes(form);
  //               return (
  //                 <ListItemButton
  //                   key={text}
  //                   component={Link}
  //                   to={href}
  //                   selected={location.pathname === href}
  //                 >
  //                   <ListItemIcon>
  //                     {complete ? (
  //                       <CheckCircleIcon sx={{ color: "green" }} />
  //                     ) : (
  //                       <RadioButtonUncheckedIcon />
  //                     )}
  //                   </ListItemIcon>
  //                   <ListItemText primary={text} />
  //                 </ListItemButton>
  //               );
  //             }
  //           })}

  //         {userId && (
  //           <ListItemButton
  //             key={"send-form"}
  //             component={Link}
  //             disabled={!showSendForms}
  //             to={"send-form"}
  //             selected={location.pathname === "/send-form"}
  //           >
  //             <ListItemIcon>
  //               <ContentPasteIcon />
  //             </ListItemIcon>
  //             <ListItemText primary={"Send Forms"} />
  //           </ListItemButton>
  //         )}
  //       </List>
  //       {user && <Divider />}
  //       <List>
  //         {linkMapStatic.map(({ text, href, icon, signedIn }) => {
  //           if (signedIn === Boolean(user)) {
  //             return (
  //               <ListItem button key={text} component={Link} to={href}>
  //                 <ListItemIcon>{icon}</ListItemIcon>
  //                 <ListItemText primary={text} />
  //               </ListItem>
  //             );
  //           }
  //         })}
  //       </List>
  //     </DrawerContainer>
  //   </div>
  // );

  // const menu = user ? signedInMenu : signedOutMenu;
  // const drawer = user ? loggedInDrawer : loggedOutDrawer;

  return (
    <Root>
      <SnackbarProvider>
        <CssBaseline />
        <AppBarStyled
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            {isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h6" noWrap>
              DSM-5 Questionnaire
            </Typography>
            {/* <AvatarStyled onClick={handleClick}>A</AvatarStyled> */}
            {menu}
          </Toolbar>
        </AppBarStyled>
        <nav>
          <Drawer
            variant={isMobile ? "temporary" : "permanent"}
            open={isMobile ? mobileOpen : true}
            onClose={handleDrawerToggle}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
        <Container maxWidth={false} style={{ margin: 0, padding: 0 }}>
          <ToolbarStyled />
          {children}
        </Container>
      </SnackbarProvider>
    </Root>
  );
};

export default Layout;
