import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect } from "react";

type SexualActivityBoxProps = {
  control: any;
  setValue: any;
  watch: any;
  resetField: any;
};

const options = [
  { value: "condom", label: "Condom" },
  { value: "pill", label: "Pill" },
  { value: "iud", label: "IUD" },
  { value: "patch", label: "Patch" },
  { value: "ring", label: "Ring" },
  { value: "tubal-ligation", label: "Tubal Ligation" },
  { value: "vasectomy", label: "Vasectomy" },
  { value: "other", label: "Other" },
];

const SexualActivityBox: React.FC<SexualActivityBoxProps> = ({
  control,
  setValue,
  watch,
  resetField,
}) => {
  const active = watch("sex.sexuallyActive");

  useEffect(() => {
    if (!active) {
      resetField("sex.partners");
      resetField("sex.contraceptiveMethod");
    }
  }, [active]);

  return (
    <Box mt={3} mb={3}>
      <Typography variant="h6">Sexual Activity</Typography>
      <Controller
        name="sex.sexuallyActive"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Are you sexually active?"
            />
          </FormControl>
        )}
      />

      {active && (
        <>
          <Controller
            name="sex.partners"
            control={control}
            defaultValue={"male"}
            render={({ field }) => (
              <FormControl margin="dense" sx={{ minWidth: 175 }}>
                <InputLabel>Sexual Partners</InputLabel>
                <Select
                  {...field}
                  label="Sexual Partners"
                  sx={{ minWidth: 175 }}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="both">Both</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="sex.contraceptiveMethod"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <FormControl margin="dense" sx={{ minWidth: 200 }}>
                <InputLabel>Contraceptive Method</InputLabel>
                <Select
                  {...field}
                  label="Contraceptive Method"
                  multiple
                  renderValue={(selected: any[]) => {
                    console.log(selected);
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected &&
                          (selected as string[]).map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                      </Box>
                    );
                  }}
                  sx={{ minWidth: 200 }}
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox checked={field.value.includes(option.value)} />
                      <Typography>{option.label}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </>
      )}
    </Box>
  );
};

export default SexualActivityBox;
