import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControlLabel, Checkbox, Typography } from '@mui/material';

type CheckboxQuestionProps = {
  control: any;
  name: string;
  question: string;
  response: string | number | boolean | Date | string[][];
};

const CheckboxQuestion: React.FC<CheckboxQuestionProps> = ({
  control,
  name,
  question,
  response
}) => {
  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={response}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            }
            label={question}
          />
        )}
      />
    </div>
  );
};

export default CheckboxQuestion;
