import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";

type SpiritualityBoxProps = {
  control: any;
  watch: any;
};

const SpiritualityBox: React.FC<SpiritualityBoxProps> = ({
  control,
  watch,
}) => {
  return (
    <Box mt={3}>
      <Typography variant="h6">Spirituality</Typography>
      <Controller
        name="spirituality.feelPurpose"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Do you feel that your life has meaning and purpose?"
            />
          </FormControl>
        )}
      />
      <Controller
        name="spirituality.meaningfulRelationships"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Do you feel that you have significant, meaningful relationships?"
            />
          </FormControl>
        )}
      />
      <Controller
        name="spirituality.higherBeing"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Do you feel that you have a relationship with a higher being (God, the universe, etc?"
            />
          </FormControl>
        )}
      />
      <Controller
        name="spirituality.religiousAffiliation"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            label="Religious Affiliation"
            variant="outlined"
            margin="dense"
          />
        )}
      />
    </Box>
  );
};

export default SpiritualityBox;
