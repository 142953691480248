// listenerMiddleware.ts
import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import {
  loadInitialData,
  resetAllFormData,
  savePatientIntakeData,
} from "./formSlice";

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  matcher: isAnyOf(loadInitialData, savePatientIntakeData, resetAllFormData),
  effect: (action, listenerApi) => {
    const state = listenerApi.getState() as RootState;
    sessionStorage.setItem("reduxState", JSON.stringify(state));
  },
});
