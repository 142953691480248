import { QuestionsData } from "../types";

const defaultOptions = ["Not at all", "Bothered a little", "Bothered a lot"];

export const questions: QuestionsData = {
  title: "LEVEL 2—Somatic Symptom—Adult Patient",
  formName: "cc_level2_somatic",
  subtitle: "Patient Health Questionnaire Physical Symptoms (PHQ-15)",
  instructions:
    "On the DSM-5-TR Level 1 cross-cutting questionnaire that you just completed, you indicated that during the past 2 weeks you (the individual receiving care) have been bothered by 'unexplained aches and pains', and/or 'feeling that your illnesses are not being taken seriously enough' at a mild or greater level of severity. The questions below ask about these feelings in more detail and especially how often you (the individual receiving care) have been bothered by a list of symptoms during the past 7 days. Please respond to each item by marking ( or x) one box per row.",
  sections: [
    {
      sectionId: "section0",
      label: "Physical Symptoms (PHQ-15)",
      score: 0,
      scoreThresholds: [
        { minimum: 0, maximum: 4, evaluation: "Minimal" },
        { minimum: 5, maximum: 9, evaluation: "Low" },
        { minimum: 10, maximum: 14, evaluation: "Medium" },
        { minimum: 15, maximum: 30, evaluation: "High" },
      ],
      scoreExplanation:
        "Each item on the PHQ-15 is rated on a 3-point scale (0=not bothered at all; 1=bothered a little; 2= bothered a lot). The total score can range from 0 to 30, with higher scores indicating greater severity of somatic symptoms. ",
      sectionNumeral: "I",
      instructions: "In the past SEVEN (7) DAYS....",
      questions: [
        {
          question: "Stomach pain",
          options: ["Not at all", "Bothered a little", "Bothered a lot"],
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Back pain",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Pain in your arms, legs, or joints (knees, hips, etc.)",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question:
            "Menstrual cramps or other problems with your periods (WOMEN ONLY)",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Headaches",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Chest pain",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Dizziness",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Fainting spells",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Feeling your heart pound or race",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Shortness of breath",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Pain or problems during sexual intercourse",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Constipation, loose bowels, or diarrhea",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Nausea, gas, or indigestion",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Feeling tired or having low energy",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Trouble sleeping",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
      ],
    },
  ],
  scoring: {
    total_partial_raw_score: 0, //'Total/Partial Raw Score',
    prorated_total_raw_score: 0,
    //'Prorated Total Raw Score (if 1-3 items left unanswered)'
  },
  disclaimer:
    "Adapted from Physical Symptoms (PHQ-15) for research and evaluation purposes.",
};
