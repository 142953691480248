import React, { useEffect } from "react";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
} from "react-hook-form";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  Button,
  Box,
  Typography,
  Paper,
  Chip,
  MenuItem,
  ListItemText,
  Divider,
} from "@mui/material";
import SubstanceUseBox from "../questionBoxes/SubstanceUseBox";
import SocialHistoryBox from "../questionBoxes/SocialHistoryBox";
import SpiritualityBox from "../questionBoxes/SpiritualityBox";
import { useAuth } from "../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { savePatientIntakeData } from "../redux/formSlice";
import { RootState } from "../redux/store";
import SexualActivityBox from "../questionBoxes/SexualActivityBox";
import ExerciseBox from "../questionBoxes/ExerciseBox";
import SleepBox from "../questionBoxes/SleepBox";
import DietBox from "../questionBoxes/DietBox";
import SafetyBox from "../questionBoxes/SafetyBox";
import ProvidersBox from "../questionBoxes/ProvidersBox";
import AdditionalBox from "../questionBoxes/AdditionalBox";
import PersonalMedicalHistoryBox from "../questionBoxes/PersonalMedicalHistoryBox";
import { useSnackbar } from "../utils/SnackbarContext";
import { useNavigate } from "react-router-dom";

export interface PatientIntakeFormValues {
  patientName: string;
  dateOfBirth: string;
  gender: string;
  allergies?: string;
  medications: string;
  personalHistory_medical:
    | "allergies"
    | "asthma"
    | "cancer"
    | "diabetes"
    | "emphysema"
    | "heartDisease"
    | "hypertension"
    | "highCholesterol"
    | "hypothyroidism"
    | "migraine"
    | "kidneyDisease"
    | "stroke"
    | "substanceUse"
    | "other"
    | "none";
  personalHistory_psychiatric:
    | "anxiety"
    | "autism"
    | "bipolar"
    | "depression"
    | "ptsd"
    | "substanceUse"
    | "trauma"
    | "other"
    | "none";
  familyHistory: Record<string, string[]>;
  lastMenstrualPeriod?: string;
  pregnancyStatus?: string;
  social: {
    occupation: string;
    nightShift?: boolean;
    education: string;
    maritalStatus: string;
    numChildren: number;
  };
  spirituality: {
    feelPurpose: boolean;
    meaningfulRelationships: boolean;
    higherBeing: boolean;
    religiousAffiliation: string;
  };
  substanceUse: {
    alcohol: boolean;
    alcoholFrequency?: string;
    alcoholAmount?: string;
    tobacco: boolean;
    tobaccoPerDay?: number;
    tobaccoYears?: number;
    vape: boolean;
    vapePerDay?: number;
    vapeYears?: number;
    otherDrugs: boolean;
    otherDrugsList?: string;
    otherDrugsHowOften?: string;
    consideredQuitting?: boolean;
    usedNeedles: boolean;
    usedOthersDrugs: boolean;
  };
  sex: {
    sexuallyActive: boolean;
    partners: "male" | "female" | "both";
    contraceptiveMethod:
      | "condom"
      | "pill"
      | "ring"
      | "iud"
      | "patch"
      | "injection"
      | "vasectomy"
      | "tubal-ligation"
      | "other";
    contraceptiveOther?: string;
  };
  exercise: {
    exercise: boolean;
    exerciseType?: string;
    exerciseFrequency?: string;
    exerciseDuration?: string;
  };
  sleep: {
    hours: number;
    difficulty: boolean;
    difficultyStaying: boolean;
  };
  diet: {
    appetite: "always hungry" | "satisfied" | "low";
    nutrition: "nutritious" | "fair" | "poor";
  };
  safety: {
    home: boolean;
    guns: boolean;
    gunsLocked?: boolean;
    storeMedications: string;
    violence: boolean;
    drugs: boolean;
    selfHarm: boolean;
    elseHarm: boolean;
  };
  providers: {
    recievingCare:
      | "pcp"
      | "cardiology"
      | "pulmonology"
      | "allergy"
      | "neurology"
      | "obgyn"
      | "naturopath";
  };
  additional: {
    recentIllness: boolean;
    recentIllnessDetails?: string;
    exposure: boolean;
    exposureDetails?: string;
    militaryService: boolean;
    branch?: string;
  };
}

const conditions = [
  {
    label: "ADHD",
    tooltip: "Attention Deficit Hyperactivity Disorder",
    type: "psychiatric",
  },
  {
    label: "Anxiety",
    tooltip:
      "A mental health disorder characterized by feelings of worry or fear",
    type: "psychiatric",
  },
  {
    label: "Allergies",
    tooltip: "A condition in which the immune system reacts to substances",
    type: "medical",
  },
  {
    label: "Asthma",
    tooltip:
      "A respiratory condition marked by spasms in the bronchi of the lungs",
    type: "medical",
  },
  {
    label: "Autism",
    tooltip:
      "A developmental disorder characterized by difficulties with social interaction and communication",
    type: "psychiatric",
  },
  {
    label: "Bipolar",
    tooltip: "A mental health condition causing extreme mood swings",
    type: "psychiatric",
  },
  {
    label: "Cancer",
    tooltip: "A disease caused by an uncontrolled division of abnormal cells",
    type: "medical",
  },
  {
    label: "Depression",
    tooltip:
      "A mood disorder causing a persistent feeling of sadness and loss of interest",
    type: "psychiatric",
  },
  {
    label: "Diabetes",
    tooltip: "A disease that occurs when your blood glucose is too high",
    type: "medical",
  },
  {
    label: "Emphysema",
    tooltip: "A lung condition that causes shortness of breath",
    type: "medical",
  },
  {
    label: "Heart Disease",
    tooltip: "A range of conditions that affect your heart",
    type: "medical",
  },
  {
    label: "Hypertension",
    tooltip: "Another term for high blood pressure",
    type: "medical",
  },
  {
    label: "Migraine",
    tooltip:
      "A headache of varying intensity, often accompanied by nausea and sensitivity to light and sound",
    type: "medical",
  },
  {
    label: "PTSD",
    tooltip:
      "A disorder in which a person has difficulty recovering after experiencing or witnessing a terrifying event",
    type: "psychiatric",
  },
  {
    label: "Kidney Disease",
    tooltip: "Damage to your kidneys that leads to a loss of kidney function",
    type: "medical",
  },
  {
    label: "Stroke",
    tooltip:
      "A condition where poor blood flow to the brain results in cell death",
    type: "medical",
  },
  {
    label: "Substance Use Disorder",
    tooltip:
      "Substance Use Disorder, a condition in which the use of one or more substances leads to a clinically significant impairment or distress",
    type: "psychiatric",
  },
  {
    label: "Trauma",
    tooltip: "A deeply distressing or disturbing experience",
    type: "psychiatric",
  },
  {
    label: "Other",
    tooltip: "Other conditions not listed here",
    type: "medical",
  },
];

const relations = [
  "Mom",
  "Dad",
  "Brother",
  "Sister",
  "Maternal Grandmother",
  "Maternal Grandfather",
  "Paternal Grandmother",
  "Paternal Grandfather",
  "Child",
  "Other",
];

const FamilyHistoryDropdowns: React.FC<{
  control: any;
  type: "psychiatric" | "medical";
}> = ({ control, type }) => {
  return (
    <Box margin={"3px"}>
      <Typography variant="h6">{`Family History of ${
        type === "medical" ? "Medical" : "Mental Health"
      } Conditions`}</Typography>
      {conditions
        .filter((condition) => condition.type === type)
        .map((condition) => (
          <Controller
            key={condition.label}
            name={`familyHistory.${condition.label.toLocaleLowerCase()}`}
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <FormControl
                margin="dense"
                sx={{ width: "400px", maxWidth: "100%" }}
              >
                <InputLabel>{condition.label}</InputLabel>
                <Select
                  {...field}
                  label={condition.label}
                  multiple
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {(selected as string[]).map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {relations.map((relation) => (
                    <MenuItem key={relation} value={relation}>
                      <Checkbox
                        checked={
                          (field.value as string[]).indexOf(relation) > -1
                        }
                      />
                      <ListItemText primary={relation} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        ))}
    </Box>
  );
};

const PatientForm: React.FC = () => {
  const [processing, setProcessing] = React.useState(false);
  const { control, handleSubmit, watch, setValue, resetField } =
    useForm<PatientIntakeFormValues>();
  const gender = watch("gender"); // Watch the gender field for changes
  const medHistory = watch("personalHistory_medical");
  const allergies = medHistory?.includes("allergies");
  const { user } = useAuth();
  const { showSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const methods = useForm();

  const { patientIntake } = useSelector((state: RootState) => state.form);
  console.log("Patient Intake Data: ", patientIntake);

  useEffect(() => {
    if (patientIntake) {
      for (const [key, value] of Object.entries(patientIntake)) {
        setValue(key as keyof PatientIntakeFormValues, value as any);
      }
    }
  }, [patientIntake, setValue]);

  //reset fields useEffecr
  useEffect(() => {
    if (gender !== "female") {
      resetField("lastMenstrualPeriod");
      resetField("pregnancyStatus");
    }
    if (!allergies) {
      resetField("allergies");
    }
  }, [gender, allergies, resetField]);

  const onSubmit: SubmitHandler<PatientIntakeFormValues> = async (data) => {
    setProcessing(true);
    try {
      dispatch(savePatientIntakeData({ data }));
      console.log("Form data saved successfully!");
      showSnackbar("Form data saved successfully!", "success");
      navigate("/checklist");
    } catch (error) {
      console.error("Error saving form data");
      showSnackbar("Error saving form data", "error");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h4">Patient Intake Form</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="patientName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Patient Name"
                variant="outlined"
                margin="dense"
              />
            )}
          />
          <Controller
            name="dateOfBirth"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Date of Birth"
                type="date"
                variant="outlined"
                margin="dense"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
          <Controller
            name="gender"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl margin="dense">
                <InputLabel>Gender</InputLabel>
                <Select {...field} label="Gender" sx={{ minWidth: 100 }}>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          {gender === "female" && (
            <>
              <Controller
                name="lastMenstrualPeriod"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Last Menstrual Period"
                    type="date"
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              <Controller
                name="pregnancyStatus"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl margin="dense">
                    <InputLabel>Pregnant</InputLabel>
                    <Select {...field} label="Pregnant" sx={{ minWidth: 100 }}>
                      <MenuItem value="not_pregnant">Not Pregnant</MenuItem>
                      <MenuItem value="pregnant">Pregnant</MenuItem>
                      <MenuItem value="unknown">Unknown</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </>
          )}
          <Controller
            name="medications"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Medications"
                variant="outlined"
                fullWidth
                margin="normal"
                multiline
                rows={4}
              />
            )}
          />
          <PersonalMedicalHistoryBox
            control={control}
            watch={watch}
            type="medical"
          />

          {allergies && (
            <Controller
              name="allergies"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Allergies"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                />
              )}
            />
          )}
          <PersonalMedicalHistoryBox
            control={control}
            watch={watch}
            type="psychiatric"
          />
          <Divider />
          <FamilyHistoryDropdowns control={control} type="medical" />
          <FamilyHistoryDropdowns control={control} type="psychiatric" />
          <Divider />

          <SocialHistoryBox
            control={control}
            watch={watch}
            resetField={resetField}
          />

          <Divider />

          <SpiritualityBox control={control} watch={watch} />

          <Divider />

          <SubstanceUseBox control={control} watch={watch} />

          <Divider />

          <SexualActivityBox
            control={control}
            watch={watch}
            setValue={setValue}
            resetField={resetField}
          />

          <Divider />

          <ExerciseBox
            control={control}
            watch={watch}
            resetField={resetField}
          />

          <Divider />

          <SleepBox control={control} watch={watch} />

          <Divider />

          <DietBox control={control} watch={watch} />

          <Divider />

          <SafetyBox control={control} watch={watch} resetField={resetField} />

          <Divider />

          <ProvidersBox control={control} watch={watch} />

          <Divider />

          <AdditionalBox
            control={control}
            watch={watch}
            resetField={resetField}
          />

          <Divider />

          <Box mt={3}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Box>
        </form>
      </Paper>
    </FormProvider>
  );
};

export default PatientForm;
