import { PDFDocument, PDFPage, RGB, rgb } from "pdf-lib";
import { saveAs } from "file-saver";
import { Information, QuestionsData, SectionResponse } from "../types";
import { getTScore, isTscoresPropKey, tscores } from "../data/tscores";
import {
  getFormProratedScore,
  isQuestionScoringInverted,
} from "./formScoringUtils";

const drawAtCoordinates = async (
  page: PDFPage,
  y: number,
  x: number,
  text: string,
  color: RGB = rgb(0, 0, 0)
) => {
  if (x && y) {
    page.drawText(text || "", {
      x: x,
      y: y,
      size: 15,
      color,
    });
  } else {
    console.log("ERROR", x, y, text);
    // print stack trace
    console.trace();
  }
};

async function fetchPdfBytes(url: string): Promise<Uint8Array> {
  const response = await fetch(url);
  const arrayBuffer = await response.arrayBuffer();
  return new Uint8Array(arrayBuffer);
}

export const verticalColumnDrawOnPdf = async (filePath: string) => {
  //   const url = filePath || "assets/anxiety.pdf";
  const existingPdfBytes = await fetch(filePath).then((res) =>
    res.arrayBuffer()
  );
  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  const secondPage = pages[1];
  const { width, height } = secondPage.getSize();
  console.log(width, height);

  for (let i = 0; i < height; i += 10) {
    let color = rgb(0, 0, 0);
    for (let j = 0; j < width; j += 10) {
      if (i % 50 === 0 || j % 50 === 0) {
        color = rgb(1, 0, 0);
      } else {
        color = rgb(0, 0, 0);
      }
      drawAtCoordinates(secondPage, i, j, ".", color);
    }
  }

  const q1_rows = [480, 468, 455, 443, 430];
  const q2_rows = [402, 388, 374, 360, 346];
  const q3_rows = [318, 304, 290, 276, 262];
  const q4_rows = [230, 216, 202, 188, 174];
  const q5_rows = [151, 137, 123, 109, 95];

  const row_coords = [151, 137, 123, 109, 95, 80, 68];

  const col_coords = [50, 522];
  const totalRawScoreRow = 79;
  const proratedScoreRow = 63;

  for (let i = 0; i < row_coords.length; i++) {
    for (let j = 0; j < col_coords.length; j++) {
      drawAtCoordinates(secondPage, row_coords[i], col_coords[j], "X");
    }
  }

  drawAtCoordinates(secondPage, 702, 48, "Name");
  drawAtCoordinates(secondPage, 702, 322, "Age");
  drawAtCoordinates(secondPage, 702, 485, "Date");
  drawAtCoordinates(secondPage, 678, 479, "Relationship");
  drawAtCoordinates(secondPage, 654, 425, "Time");

  const pdfBytes = await pdfDoc.save();
  saveAs(new Blob([pdfBytes], { type: "application/pdf" }), "annotated.pdf");
};

type PDFDrawData = {
  filePath: string;
  nameCoords: [number, number];
  ageCoords: [number, number];
  dateCoords: [number, number];
  relationshipCoords: [number, number];
  timeCoords: [number, number];
  rowLocations: number[];
  columnLocations: number[];
  totalRawScoreRow: number;
  proratedScoreRow: number;
  tScoreRow: number;
  responseValsZeroIndexed: boolean;
};

const anxietyDrawData: PDFDrawData = {
  filePath: "assets/anxiety.pdf",
  nameCoords: [663, 55],
  ageCoords: [663, 305],
  dateCoords: [663, 500],
  relationshipCoords: [638, 440],
  timeCoords: [615, 390],
  rowLocations: [435, 395, 355, 316, 264, 225, 184],
  columnLocations: [250, 304, 360, 418, 472, 525],
  totalRawScoreRow: 167,
  proratedScoreRow: 151,
  tScoreRow: 134,
  responseValsZeroIndexed: false,
};

const depressionDrawData: PDFDrawData = {
  filePath: "assets/depression.pdf",
  nameCoords: [678, 77],
  ageCoords: [678, 345],
  dateCoords: [678, 503],
  relationshipCoords: [654, 510],
  timeCoords: [629, 455],
  rowLocations: [465, 435, 402, 372, 340, 307, 278, 245],
  columnLocations: [282, 331, 384, 441, 496, 552],
  totalRawScoreRow: 227,
  proratedScoreRow: 212,
  tScoreRow: 197,
  responseValsZeroIndexed: false,
};

const angerDrawData: PDFDrawData = {
  filePath: "assets/anger.pdf",
  nameCoords: [668, 107],
  ageCoords: [668, 355],
  dateCoords: [668, 505],
  relationshipCoords: [644, 492],
  timeCoords: [619, 442],
  rowLocations: [439, 406, 373, 341, 309],
  columnLocations: [264, 319, 375, 431, 485, 542],
  totalRawScoreRow: 291,
  proratedScoreRow: 273,
  tScoreRow: 255,
  responseValsZeroIndexed: false,
};

export const drawOnPdf = async (filePath: string) => {
  const existingPdfBytes = await fetch(filePath).then((res) =>
    res.arrayBuffer()
  );
  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  const pages = pdfDoc.getPages();
  const secondPage = pages[0];
  const { width, height } = secondPage.getSize();
  console.log(width, height);

  for (let i = 0; i < height; i += 10) {
    let color = rgb(0, 0, 0);
    for (let j = 0; j < width; j += 10) {
      if (i % 50 === 0 || j % 50 === 0) {
        color = rgb(1, 0, 0);
      } else {
        color = rgb(0, 0, 0);
      }
      drawAtCoordinates(secondPage, i, j, ".", color);
    }
  }

  let row_coords = [620, 596, 569, 545, 520, 496, 472];
  row_coords = row_coords.concat([5]);
  const col_coords = [316, 363, 415, 474];

  const anchor = [316, 472]; // 322, 180.5  = plus 6, minus 291.5
  //317, 156.5
  //368, 156.5
  //426, 156.5
  //474, 156.5
  //471, 133.5
  //98, 48.5
  //231, 48.5
  //338, 47.5
  //440, 475.

  for (let i = 0; i < row_coords.length; i++) {
    for (let j = 0; j < col_coords.length; j++) {
      drawAtCoordinates(secondPage, row_coords[i], col_coords[j], "O");
    }
  }

  drawAtCoordinates(secondPage, 708, 119, "Name");
  // drawAtCoordinates(secondPage, 665, 338, "Age");
  drawAtCoordinates(secondPage, 708, 460, "Date");
  // drawAtCoordinates(secondPage, 640, 496, "Relationship");
  // drawAtCoordinates(secondPage, 617, 440, "Time");

  const pdfBytes = await pdfDoc.save();
  saveAs(new Blob([pdfBytes], { type: "application/pdf" }), "annotated.pdf");
};

const somaticDrawData: PDFDrawData = {
  filePath: "assets/somatic.pdf",
  nameCoords: [665, 62],
  ageCoords: [665, 338],
  dateCoords: [665, 485],
  relationshipCoords: [640, 496],
  timeCoords: [617, 440],
  rowLocations: [
    414, 394, 374, 355, 323, 303, 283, 263, 243, 223, 203, 183, 163, 143, 123,
    104, 88,
  ],
  columnLocations: [329, 394, 463, 537],
  totalRawScoreRow: 104,
  proratedScoreRow: 88,
  tScoreRow: 88,
  responseValsZeroIndexed: true,
};

const ocdDrawData: PDFDrawData = {
  filePath: "assets/ocd.pdf",
  nameCoords: [668, 52],
  ageCoords: [668, 323],
  dateCoords: [668, 485],
  relationshipCoords: [643, 482],
  timeCoords: [620, 422],
  rowLocations: [442, 388, 331, 264, 195],
  columnLocations: [172, 240, 324, 411, 490, 550],
  totalRawScoreRow: 119,
  proratedScoreRow: 101,
  tScoreRow: 84,
  responseValsZeroIndexed: true,
};

const sleepDrawData: PDFDrawData = {
  filePath: "assets/sleep.pdf",
  nameCoords: [664, 55],
  ageCoords: [664, 328],
  dateCoords: [664, 489],
  relationshipCoords: [638, 487],
  timeCoords: [615, 428],
  rowLocations: [464, 428, 398, 366, 305, 273, 241, 183],
  columnLocations: [249, 304, 360, 417, 470, 525],
  totalRawScoreRow: 165,
  proratedScoreRow: 150,
  tScoreRow: 135,
  responseValsZeroIndexed: false,
};

const loadPageOfPdf = async (url: string, pageIndex: number) => {
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const newPdfDoc = await PDFDocument.create();
  const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [pageIndex]);
  newPdfDoc.addPage(copiedPage);
  // const pdfBytes = await newPdfDoc.save();

  return newPdfDoc;
};
const generateForm = async (
  drawData: PDFDrawData,
  sectionResponses: SectionResponse[],
  info: Information,
  formName: string
): Promise<PDFDocument> => {
  const url = drawData.filePath;
  const pdfDoc = await loadPageOfPdf(url, 1);
  const page = pdfDoc.getPage(0);
  const rows = drawData.rowLocations;
  const columns = drawData.columnLocations;

  let totalScore = 0;
  let numSelections = 0;

  const scoringColumn = formName === "cc_level2_somatic" ? 3 : 5;

  sectionResponses.forEach((section) => {
    section.questions.forEach((question, idx) => {
      if (typeof question === "number") {
        drawAtCoordinates(page, rows[idx], columns[question || 0], "X");
        const inv = isQuestionScoringInverted(formName, section.sectionId, idx);
        let questionValue = inv ? 4 - question : question;
        if (!drawData.responseValsZeroIndexed) {
          questionValue += 1;
        }
        drawAtCoordinates(
          page,
          rows[idx],
          columns[scoringColumn],
          String(questionValue)
        );

        totalScore += questionValue;
        numSelections += 1;
      }
    });

    const proratedScore = getFormProratedScore(
      formName,
      totalScore,
      numSelections
    );

    console.log("formName", formName);
    const tScore = getTScore(formName, proratedScore);
    const averageScore = Math.ceil(totalScore / numSelections);
    console.log(
      `Total Score: ${totalScore}, T-Score: ${tScore}, Form: ${formName}, Prorated Score ${proratedScore}`
    );

    drawAtCoordinates(
      page,
      drawData.totalRawScoreRow,
      columns[scoringColumn],
      String(totalScore)
    );
    drawAtCoordinates(
      page,
      drawData.proratedScoreRow,
      columns[scoringColumn],
      String(proratedScore)
    );
    // if it's the OCD form, tscore is actually the average score.
    if (formName === "cc_level2_somatic") {
      //don't print t-score for somatic symptoms
    } else if (formName === "cc_level2_ocd") {
      drawAtCoordinates(
        page,
        drawData.tScoreRow,
        columns[scoringColumn],
        String(averageScore)
      );
    } else {
      drawAtCoordinates(
        page,
        drawData.tScoreRow,
        columns[scoringColumn] - 7,
        String(tScore)
      );
    }
  });

  drawAtCoordinates(
    page,
    drawData.nameCoords[0],
    drawData.nameCoords[1],
    info.name
  );
  drawAtCoordinates(
    page,
    drawData.ageCoords[0],
    drawData.ageCoords[1],
    String(info.age)
  );
  drawAtCoordinates(
    page,
    drawData.dateCoords[0],
    drawData.dateCoords[1],
    new Date().toLocaleDateString("en-US")
  );
  drawAtCoordinates(
    page,
    drawData.relationshipCoords[0],
    drawData.relationshipCoords[1],
    info.relationship
  );
  drawAtCoordinates(
    page,
    drawData.timeCoords[0],
    drawData.timeCoords[1],
    info.time?.toString()
  );

  return pdfDoc;
};

const level1DrawData: PDFDrawData = {
  filePath: "assets/level1.pdf",
  nameCoords: [722, 62],
  ageCoords: [722, 263],
  dateCoords: [722, 515],
  relationshipCoords: [697, 424],
  timeCoords: [685, 380],
  rowLocations: [
    583, 566, 548, 531, 513, 484, 466, 449, 431, 414, 396, 378, 349, 319, 302,
    272, 254, 225, 195, 177, 160, 142, 125,
  ],
  columnLocations: [386, 422, 460, 498, 537, 572],
  totalRawScoreRow: 0,
  proratedScoreRow: 0,
  tScoreRow: 0,
  responseValsZeroIndexed: false,
};

const generateLevel1Form = async (
  sectionResponses: SectionResponse[],
  info: Information
) => {
  const drawData = level1DrawData;
  const url = drawData.filePath;
  const pdfDoc = await loadPageOfPdf(url, 1);
  const page = pdfDoc.getPage(0);

  let currentRow = 0;

  for (let i = 0; i < sectionResponses.length; i++) {
    const section = sectionResponses.find(
      (section) => section.sectionId === `section${i}`
    );
    if (!section) {
      continue;
    }
    const sectionIdx = parseInt(section.sectionId.split("section")[1]);
    let score = 0;

    section.questions.forEach((question, idx) => {
      if (typeof question === "number") {
        drawAtCoordinates(
          page,
          drawData.rowLocations[currentRow],
          drawData.columnLocations[question || 0],
          "O"
        );
        score = Math.max(score, question);
        currentRow += 1;
      }
    });

    const scoreRow =
      (drawData.rowLocations[currentRow - 1] +
        drawData.rowLocations[currentRow - section.questions.length]) /
      2;

    // Draw the section score
    drawAtCoordinates(
      page,
      scoreRow,
      drawData.columnLocations[5],
      String(score)
    );
  }

  drawAtCoordinates(
    page,
    drawData.nameCoords[0],
    drawData.nameCoords[1],
    info.name
  );
  drawAtCoordinates(
    page,
    drawData.ageCoords[0],
    drawData.ageCoords[1],
    info.age?.toString()
  );
  drawAtCoordinates(
    page,
    drawData.dateCoords[0],
    drawData.dateCoords[1],
    new Date().toLocaleDateString("en-US")
  );
  drawAtCoordinates(
    page,
    drawData.relationshipCoords[0],
    drawData.relationshipCoords[1],
    info.relationship
  );
  drawAtCoordinates(
    page,
    drawData.timeCoords[0],
    drawData.timeCoords[1],
    info.time?.toString()
  );

  return pdfDoc;
};

const generateAnxietyForm = async (
  sectionResponses: SectionResponse[],
  info: Information
) => {
  return generateForm(
    anxietyDrawData,
    sectionResponses,
    info,
    "cc_level2_anxiety"
  );
};

const generateOnlyLevel1Form = async (
  sectionResponses: SectionResponse[],
  info: Information
) => {
  const doc = await generateLevel1Form(sectionResponses, info);
  const pdfBytes = await doc.save();
  saveAs(new Blob([pdfBytes], { type: "application/pdf" }), "level1.pdf");
};

const generateDepressionForm = async (
  sectionResponses: SectionResponse[],
  info: Information
) => {
  console.log("depression data", sectionResponses);
  return generateForm(
    depressionDrawData,
    sectionResponses,
    info,
    "cc_level2_depression"
  );
};

const generateAngerForm = async (
  sectionResponses: SectionResponse[],
  info: Information
) => {
  return generateForm(angerDrawData, sectionResponses, info, "cc_level2_anger");
};

const generateAsrsForm = async (
  sectionResponses: SectionResponse[],
  info: Information
) => {
  const url = "assets/asrs.pdf";
  const pdfDoc = await loadPageOfPdf(url, 1);
  const page = pdfDoc.getPage(0);

  const row_coords = [
    627, 596, 565, 534, 503, 471, 422, 390, 358, 325, 290, 261, 226, 193, 163,
    133, 96, 62,
  ];
  const columns = [435, 467, 497, 531, 560];

  let currentRow = 0;
  for (let j = 0; j < sectionResponses.length; j++) {
    const section = sectionResponses.find(
      (section) => section.sectionId === `section${j}`
    );
    if (!section) {
      continue;
    }
    console.log("RCD", section);
    for (let i = 0; i < section.questions.length; i++) {
      const question = section.questions[i];
      if (typeof question === "number") {
        drawAtCoordinates(page, row_coords[currentRow], columns[question], "X");
        currentRow += 1;
      }
    }
  }

  drawAtCoordinates(page, 718, 169, info.name);
  drawAtCoordinates(page, 718, 460, new Date().toLocaleDateString("en-US"));
  return pdfDoc;
};

const generateGadForm = async (
  sectionResponses: SectionResponse[],
  info: Information
) => {
  const url = "assets/gad7.pdf";
  const pdfDoc = await loadPageOfPdf(url, 0);
  const page = pdfDoc.getPage(0);

  let row_coords = [620, 596, 569, 545, 520, 496, 472];
  // row_coords = row_coords.concat([5]);
  const col_coords = [316, 363, 415, 474];
  // const anchor = [316, 472]; // 322, 180.5  = plus 6, minus 291.5
  //317, 156.5
  //368, 156.5
  //426, 156.5
  //474, 156.5
  //471, 133.5
  //98, 48.5
  //231, 48.5
  //338, 47.5
  //440, 475.
  const section = sectionResponses[0];

  console.log("RCD", section);
  for (let i = 0; i < section.questions.length; i++) {
    const question = section.questions[i];
    if (typeof question === "number") {
      drawAtCoordinates(page, row_coords[i], col_coords[question], "O");
    }
  }

  return pdfDoc;
};

const generatePhqForm = async (
  sectionResponses: SectionResponse[],
  info: Information
) => {
  const url = "assets/PHQ9.pdf";
  const pdfDoc = await loadPageOfPdf(url, 0);
  const page = pdfDoc.getPage(0);

  const row_coords = [605, 571, 535, 499, 463, 420, 369, 305, 238, 193, 163];
  const col_coords = [369, 426, 484, 542];

  for (let j = 0; j < sectionResponses.length; j++) {
    const section = sectionResponses.find(
      (section) => section.sectionId === `section${j}`
    );
    if (!section) {
      continue;
    }
    console.log("RCD", section);
    for (let i = 0; i < section.questions.length; i++) {
      const question = section.questions[i];
      if (typeof question === "number") {
        drawAtCoordinates(page, row_coords[i], col_coords[question], "O");
      }
    }
  }

  const nameCoords = [708, 119];
  const dateCoords = [708, 460];

  drawAtCoordinates(page, nameCoords[0], nameCoords[1], info.name);
  drawAtCoordinates(
    page,
    dateCoords[0],
    dateCoords[1],
    new Date().toLocaleDateString("en-US")
  );
  return pdfDoc;
};

const generateManiaForm = async (
  sectionResponses: SectionResponse[],
  info: Information
) => {
  const url = "assets/mania.pdf";
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
  const pdfDoc = await loadPageOfPdf(url, 1);
  const page = pdfDoc.getPage(0);

  const totalRawScoreRow = 79;
  const proratedScoreRow = 63;

  const row_coords = [
    [480, 468, 455, 443, 431],
    [402, 388, 374, 360, 347],
    [318, 304, 290, 276, 264],
    [234, 220, 206, 184, 180],
    [151, 137, 123, 109, 97],
  ];

  const nameCoords = [702, 48];
  const ageCoords = [702, 322];
  const dateCoords = [702, 485];
  const relationshipCoords = [678, 479];
  const timeCoords = [654, 425];

  const col_coords = [50, 522];

  // console.log("questionsData MANIA", questionsData);

  let score = 0;
  let questionsAnswered = 0;
  sectionResponses.forEach((section) => {
    section.questions.forEach((question, idx) => {
      if (typeof question === "number") {
        drawAtCoordinates(page, row_coords[idx][question], col_coords[0], "X");
        const questionValue = question + 1;
        drawAtCoordinates(
          page,
          row_coords[idx][4] - 2,
          col_coords[1],
          String(questionValue)
        );
        score += questionValue;
        questionsAnswered += 1;
      }
    });
  });

  // const score = questionsData.sections[0].score;
  const proratedScore = getFormProratedScore(
    "cc_level2_mania",
    score,
    questionsAnswered
  );

  drawAtCoordinates(page, totalRawScoreRow, col_coords[1], String(score));
  drawAtCoordinates(
    page,
    proratedScoreRow,
    col_coords[1],
    String(proratedScore)
  );

  drawAtCoordinates(page, nameCoords[0], nameCoords[1], info.name);
  drawAtCoordinates(page, ageCoords[0], ageCoords[1], info.age?.toString());
  drawAtCoordinates(
    page,
    dateCoords[0],
    dateCoords[1],
    new Date().toLocaleDateString("en-US")
  );
  drawAtCoordinates(
    page,
    relationshipCoords[0],
    relationshipCoords[1],
    info.relationship
  );
  drawAtCoordinates(page, timeCoords[0], timeCoords[1], info.time?.toString());

  return pdfDoc;
};

const generateOcdForm = async (
  sectionResponseArray: SectionResponse[],
  info: Information
) => {
  return generateForm(ocdDrawData, sectionResponseArray, info, "cc_level2_ocd");
};

const generateSomaticForm = async (
  sectionResponseArray: SectionResponse[],
  info: Information
) => {
  return generateForm(
    somaticDrawData,
    sectionResponseArray,
    info,
    "cc_level2_somatic"
  );
};

const generateSleepForm = async (
  sectionResponseArray: SectionResponse[],
  info: Information
) => {
  return generateForm(
    sleepDrawData,
    sectionResponseArray,
    info,
    "cc_level2_sleep"
  );
};

async function addFormToMergedPdf(
  mergedPdf: PDFDocument,
  formGenerator: (
    sectionResponseArray: SectionResponse[],
    info: Information
  ) => Promise<PDFDocument>,
  sectionResponseArray: SectionResponse[] | undefined,
  info: Information
): Promise<void> {
  const formPdf = await formGenerator(sectionResponseArray || [], info);
  const pages = await mergedPdf.copyPages(formPdf, formPdf.getPageIndices());
  pages.forEach((page) => mergedPdf.addPage(page));
}

export async function generateAllForms(
  formsData: Map<string, SectionResponse[]>,
  info: Information
): Promise<void> {
  const mergedPdf = await PDFDocument.create();

  console.log("formsData", formsData, info);

  const formGenerators = Array.from(formsData.entries())
    .map(([formName, formData]) => {
      switch (formName) {
        case "cc_level1":
          return { form: generateLevel1Form, data: formData, order: 1 };
        case "cc_level2_anxiety":
          return { form: generateAnxietyForm, data: formData, order: 2 };
        case "cc_level2_depression":
          return { form: generateDepressionForm, data: formData, order: 3 };
        case "cc_level2_anger":
          return { form: generateAngerForm, data: formData, order: 4 };
        case "cc_level2_mania":
          return { form: generateManiaForm, data: formData, order: 5 };
        case "cc_level2_ocd":
          return { form: generateOcdForm, data: formData, order: 6 };
        case "cc_level2_sleep":
          return { form: generateSleepForm, data: formData, order: 7 };
        case "cc_level2_somatic":
          return { form: generateSomaticForm, data: formData, order: 7 };
        case "asrs":
          return { form: generateAsrsForm, data: formData, order: 8 };
        case "phq9":
          return { form: generatePhqForm, data: formData, order: 9 };
        case "patientIntake":
          return { form: generateLevel1Form, data: formData, order: 10 };
        case "gad7":
          return { form: generateGadForm, data: formData, order: 11 };
        case "mdq":
          return { form: generateGadForm, data: formData, order: 12 };
        default:
          throw new Error(`Unknown form name: ${formName}`);
      }
    })
    .sort((a, b) => a.order - b.order);

  for (const { form, data } of formGenerators) {
    await addFormToMergedPdf(mergedPdf, form, data, info);
  }

  const mergedPdfBytes = await mergedPdf.save();
  saveAs(
    new Blob([mergedPdfBytes], { type: "application/pdf" }),
    "combinedForms.pdf"
  );
}