import React from 'react';
import { Control } from 'react-hook-form';
import RadioQuestion from './RadioQuestion';
import DropdownQuestion from './DropdownQuestion';
import CheckboxQuestion from './CheckboxQuestion';
import TextQuestion from "./TextQuestion";
import { Box, Typography } from "@mui/material";
import { FormData, Question, QuestionsData } from "../types";
import ToggleGroupQuestion from "./ToggleGroupQuestion";

interface Props {
  control: Control<FormData, any>;
  label: string;
  sectionId: string;
  instructions: string;
  questions: Question[];
  responses: number[] | string[] | boolean[] | Date[];
}

const Section: React.FC<Props> = ({
  control,
  sectionId,
  instructions,
  questions,
  label,
  responses = [],
}) => {
  const renderQuestion = (question: Question, idx: number) => {
    switch (question.type) {
      case "radio":
        return (
          <RadioQuestion
            key={idx}
            control={control}
            name={`${sectionId}-${idx}`}
            question={question.question}
            options={question.options}
            response={responses[idx]}
          />
        );
      case "dropdown":
        return (
          <DropdownQuestion
            key={idx}
            control={control}
            name={`${sectionId}-${idx}`}
            question={question.question}
            options={question.options}
            response={responses[idx]}
          />
        );
      case "checkbox":
        return (
          <CheckboxQuestion
            key={idx}
            control={control}
            name={`${sectionId}-${idx}`}
            question={question.question}
            response={responses[idx]}
          />
        );
      case "text":
        return (
          <TextQuestion
            key={idx}
            control={control}
            name={`${sectionId}-${idx}`}
            question={question.question}
            response={responses[idx] as string}
          />
        );
      case "toggle_group":
        return (
          <ToggleGroupQuestion
            key={idx}
            control={control}
            name={`${sectionId}-${idx}`}
            question={question.question}
            response={responses[idx]}
            options={question.options}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      <Typography variant="h4">{label}</Typography>
      <Typography variant="h6">{instructions}</Typography>
      {questions.map((question, idx) => (
        <Box key={`section-div-${idx}`} sx={{ padding: "1px" }}>
          {renderQuestion(question, idx)}
        </Box>
      ))}
    </Box>
  );
};

export default Section;
