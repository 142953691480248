"use client";
  import { FormEvent, useEffect, useState } from "react";
  import {
    TextField,
    Button,
    Container,
    Typography,
    Box,
    Link,
    Alert,
    Snackbar,
  } from "@mui/material";
  import { useNavigate } from "react-router-dom";
  import {
    UserCredential,
    signInWithEmailAndPassword,
    AuthErrorCodes,
  } from "firebase/auth";
  import { auth } from "../firebaseConfig";
import { useAuth } from "../hooks/useAuth";

export default function LoginPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");

    setLoading(true);

    signInWithEmailAndPassword(auth, email as string, password as string)
      .then((user: UserCredential) => {
        if (user.user && !user.user.emailVerified) {
          setSnackbarMessage(
            "Please verify your email first and sign in again"
          );
          setSnackbarOpen(true);
          setLoading(false);
          return;
        }
        navigate("/");
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);

        switch (error.code) {
          case AuthErrorCodes.INVALID_LOGIN_CREDENTIALS:
            setSnackbarMessage("Incorrect password. Please try again.");
            break;
          case AuthErrorCodes.USER_DELETED:
            setSnackbarMessage("No account found with this email.");
            break;
          case AuthErrorCodes.INVALID_EMAIL:
            setSnackbarMessage(
              "Invalid email format. Please enter a valid email."
            );
            break;
          case AuthErrorCodes.USER_DISABLED:
            setSnackbarMessage(
              "This account has been disabled. Please contact support."
            );
            break;
          case AuthErrorCodes.INVALID_LOGIN_CREDENTIALS:
            setSnackbarMessage("Invalid sign in.  Please try again");
            break;
          default:
            console.log(error.code);
            setSnackbarMessage("An error occurred. Please try again.");
            break;
        }

        setSnackbarOpen(true);
      });
  }

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleSignUp = () => {
    navigate("/sign-up");
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link href="#" variant="body2" onClick={handleForgotPassword}>
              Forgot password?
            </Link>
            <Link href="#" variant="body2" onClick={handleSignUp}>
              {"Don't have an account? Sign Up"}
            </Link>
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
