type tscore = {
  raw: number;
  tScore: number;
  se: number;
};

export type TscoresProps = {
  cc_level2_depression: tscore[];
  cc_level2_anger: tscore[];
  cc_level2_anxiety: tscore[];
  cc_level2_sleep: tscore[];
};

// Type guard function
export function isTscoresPropKey(key: string): key is keyof TscoresProps {
  return (
    key in
    {
      cc_level2_depression: null,
      cc_level2_anger: null,
      cc_level2_anxiety: null,
      cc_level2_sleep: null,
    }
  );
}

export const tscores: TscoresProps = {
  cc_level2_depression: [
    { raw: 8, tScore: 37.1, se: 5.5 },
    { raw: 9, tScore: 43.3, se: 3.4 },
    { raw: 10, tScore: 46.2, se: 2.8 },
    { raw: 11, tScore: 48.2, se: 2.4 },
    { raw: 12, tScore: 49.8, se: 2.2 },
    { raw: 13, tScore: 51.2, se: 2.0 },
    { raw: 14, tScore: 52.3, se: 1.9 },
    { raw: 15, tScore: 53.4, se: 1.8 },
    { raw: 16, tScore: 54.3, se: 1.8 },
    { raw: 17, tScore: 55.3, se: 1.7 },
    { raw: 18, tScore: 56.2, se: 1.7 },
    { raw: 19, tScore: 57.1, se: 1.7 },
    { raw: 20, tScore: 57.9, se: 1.7 },
    { raw: 21, tScore: 58.8, se: 1.7 },
    { raw: 22, tScore: 59.7, se: 1.8 },
    { raw: 23, tScore: 60.7, se: 1.8 },
    { raw: 24, tScore: 61.6, se: 1.8 },
    { raw: 25, tScore: 62.5, se: 1.8 },
    { raw: 26, tScore: 63.5, se: 1.8 },
    { raw: 27, tScore: 64.4, se: 1.8 },
    { raw: 28, tScore: 65.4, se: 1.8 },
    { raw: 29, tScore: 66.4, se: 1.8 },
    { raw: 30, tScore: 67.4, se: 1.8 },
    { raw: 31, tScore: 68.3, se: 1.8 },
    { raw: 32, tScore: 69.3, se: 1.8 },
    { raw: 33, tScore: 70.4, se: 1.8 },
    { raw: 34, tScore: 71.4, se: 1.8 },
    { raw: 35, tScore: 72.5, se: 1.8 },
    { raw: 36, tScore: 73.6, se: 1.8 },
    { raw: 37, tScore: 74.8, se: 1.8 },
    { raw: 38, tScore: 76.2, se: 1.9 },
    { raw: 39, tScore: 77.9, se: 2.4 },
    { raw: 40, tScore: 81.1, se: 3.4 },
  ],
  cc_level2_anger: [
    { raw: 5, tScore: 32.9, se: 5.3 },
    { raw: 6, tScore: 38.1, se: 4.0 },
    { raw: 7, tScore: 41.3, se: 3.7 },
    { raw: 8, tScore: 44.0, se: 3.5 },
    { raw: 9, tScore: 46.3, se: 3.4 },
    { raw: 10, tScore: 48.4, se: 3.3 },
    { raw: 11, tScore: 50.5, se: 3.3 },
    { raw: 12, tScore: 52.6, se: 3.2 },
    { raw: 13, tScore: 54.7, se: 3.2 },
    { raw: 14, tScore: 56.7, se: 3.2 },
    { raw: 15, tScore: 58.8, se: 3.2 },
    { raw: 16, tScore: 60.8, se: 3.2 },
    { raw: 17, tScore: 62.9, se: 3.2 },
    { raw: 18, tScore: 65.0, se: 3.2 },
    { raw: 19, tScore: 67.2, se: 3.2 },
    { raw: 20, tScore: 69.4, se: 3.3 },
    { raw: 21, tScore: 71.7, se: 3.3 },
    { raw: 22, tScore: 74.1, se: 3.3 },
    { raw: 23, tScore: 76.8, se: 3.4 },
    { raw: 24, tScore: 79.7, se: 3.5 },
    { raw: 25, tScore: 83.3, se: 3.9 },
  ],
  // cc_level2_somatic: ["N/A"],
  cc_level2_anxiety: [
    { raw: 7, tScore: 36.3, se: 5.4 },
    { raw: 8, tScore: 42.1, se: 3.4 },
    { raw: 9, tScore: 44.7, se: 2.9 },
    { raw: 10, tScore: 46.7, se: 2.6 },
    { raw: 11, tScore: 48.4, se: 2.4 },
    { raw: 12, tScore: 49.9, se: 2.3 },
    { raw: 13, tScore: 51.3, se: 2.3 },
    { raw: 14, tScore: 52.6, se: 2.2 },
    { raw: 15, tScore: 53.8, se: 2.2 },
    { raw: 16, tScore: 55.1, se: 2.2 },
    { raw: 17, tScore: 56.3, se: 2.2 },
    { raw: 18, tScore: 57.6, se: 2.2 },
    { raw: 19, tScore: 58.8, se: 2.2 },
    { raw: 20, tScore: 60.0, se: 2.2 },
    { raw: 21, tScore: 61.3, se: 2.2 },
    { raw: 22, tScore: 62.6, se: 2.2 },
    { raw: 23, tScore: 63.8, se: 2.2 },
    { raw: 24, tScore: 65.1, se: 2.2 },
    { raw: 25, tScore: 66.4, se: 2.2 },
    { raw: 26, tScore: 67.7, se: 2.2 },
    { raw: 27, tScore: 68.9, se: 2.2 },
    { raw: 28, tScore: 70.2, se: 2.2 },
    { raw: 29, tScore: 71.5, se: 2.2 },
    { raw: 30, tScore: 72.9, se: 2.2 },
    { raw: 31, tScore: 74.3, se: 2.2 },
    { raw: 32, tScore: 75.8, se: 2.3 },
    { raw: 33, tScore: 77.4, se: 2.4 },
    { raw: 34, tScore: 79.5, se: 2.7 },
    { raw: 35, tScore: 82.7, se: 3.5 },
  ],
  cc_level2_sleep: [
    { raw: 8, tScore: 28.9, se: 4.8 },
    { raw: 9, tScore: 33.1, se: 3.7 },
    { raw: 10, tScore: 35.9, se: 3.3 },
    { raw: 11, tScore: 38.0, se: 3.0 },
    { raw: 12, tScore: 39.8, se: 2.9 },
    { raw: 13, tScore: 41.4, se: 2.8 },
    { raw: 14, tScore: 42.9, se: 2.7 },
    { raw: 15, tScore: 44.2, se: 2.7 },
    { raw: 16, tScore: 45.5, se: 2.6 },
    { raw: 17, tScore: 46.7, se: 2.6 },
    { raw: 18, tScore: 47.9, se: 2.6 },
    { raw: 19, tScore: 49.0, se: 2.6 },
    { raw: 20, tScore: 50.1, se: 2.5 },
    { raw: 21, tScore: 51.2, se: 2.5 },
    { raw: 22, tScore: 52.2, se: 2.5 },
    { raw: 23, tScore: 53.3, se: 2.5 },
    { raw: 24, tScore: 54.3, se: 2.5 },
    { raw: 25, tScore: 55.3, se: 2.5 },
    { raw: 26, tScore: 56.3, se: 2.5 },
    { raw: 27, tScore: 57.3, se: 2.5 },
    { raw: 28, tScore: 58.3, se: 2.5 },
    { raw: 29, tScore: 59.4, se: 2.5 },
    { raw: 30, tScore: 60.4, se: 2.5 },
    { raw: 31, tScore: 61.5, se: 2.5 },
    { raw: 32, tScore: 62.6, se: 2.5 },
    { raw: 33, tScore: 63.7, se: 2.6 },
    { raw: 34, tScore: 64.9, se: 2.6 },
    { raw: 35, tScore: 66.1, se: 2.7 },
    { raw: 36, tScore: 67.5, se: 2.8 },
    { raw: 37, tScore: 69.0, se: 3.0 },
    { raw: 38, tScore: 70.8, se: 3.2 },
    { raw: 39, tScore: 73.0, se: 3.5 },
    { raw: 40, tScore: 76.5, se: 4.4 },
  ],
  // cc_level2_adhd: ["N/A"],
  // cc_level2_ocd: ["N/A"],
  // cc_level2_mania: ["N/A"],
  // gad7: ["N/A"],
};

export function getTScore(formName: string, rawScore: number): number {
  if (!isTscoresPropKey(formName)) {
    return 0;
  }
  // if rawScore isn't an integer, round up
  rawScore = Math.ceil(rawScore);

  const tscoresArray = tscores[formName];
  const tscore = tscoresArray.find((tscore) => tscore.raw === rawScore);
  return tscore ? tscore.tScore : 0;
}