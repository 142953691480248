import { QuestionsData } from "../types";

const defaultOptions = ["Never", "Rarely", "Sometimes", "Often", "Very Often"];

export const questions: QuestionsData = {
  title: "Adult ADHD Self-Report Scale (ASRS-v1.1)",
  formName: "asrs",
  instructions:
    "Please answer the questions below, rating yourself on each of the criteria shown using the scale on the right side of the page. As you answer each question, place an X in the box that best describes how you have felt and conducted yourself over the past 6 months. Please give this completed checklist to your healthcare professional to discuss during today’s appointment.",
  sections: [
    {
      sectionId: "section0",
      sectionNumeral: "I",
      label: "Section A",
      score: 0,
      scoreThresholds: [],
      scoreExplanation:
        "Score Part A. If four or more marks appear in the darkly shaded boxes within Part A then the patient has symptoms highly consistent with ADHD in adults and further investigation is warranted.",
      instructions:
        "Please answer the questions below, rating yourself on each of the criteria shown using the scale on the right side of the page.  Consider work/school, social and family settings.",
      questions: [
        {
          question:
            "How often do you have trouble wrapping up the final details of a project, once the challenging parts have been done?",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
        {
          question:
            "How often do you have difficulty getting things in order when you have to do a task that requires organization?",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
        {
          question:
            "How often do you have problems remembering appointments or obligations?",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
        {
          question:
            "When you have a task that requires a lot of thought, how often do you avoid or delay getting started?",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
        {
          question:
            "How often do you fidget or squirm with your hands or feet when you have to sit down for a long time?",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
        {
          question:
            "How often do you feel overly active and compelled to do things, like you were driven by a motor?",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
      ],
    },
    {
      sectionId: "section1",
      sectionNumeral: "II",
      label: "Section B",
      score: 0,
      scoreThresholds: [],
      scoreExplanation:
        "The frequency scores on Part B provide additional cues and can serve as further probes into the patient’s symptoms. Pay particular attention to marks appearing in the dark shaded boxes. The frequency-based response is more sensitive with certain questions. No total score or diagnostic likelihood is utilized for the twelve questions. It has been found that the six questions in Part A are the most predictive of the disorder and are best for use as a screening instrument.",
      instructions:
        "Please answer the questions below, rating yourself on each of the criteria shown using the scale on the right side of the page.  Consider work/school, social and family settings.",
      questions: [
        {
          question:
            "How often do you make careless mistakes when you have to work on a boring or difficult project?",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
        {
          question:
            "How often do you have difficulty keeping your attention when you are doing boring or repetitive work?",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
        {
          question:
            "How often do you have difficulty concentrating on what people say to you, even when they are speaking to you directly?",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
        {
          question:
            "How often do you misplace or have difficulty finding things at home or at work?",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
        {
          question:
            "How often are you distracted by activity or noise around you?",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
        {
          question:
            "How often do you leave your seat in meetings or other situations in which you are expected to remain seated?",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
        {
          question: "How often do you feel restless or fidgety?",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
        {
          question:
            "How often do you have difficulty unwinding and relaxing when you have time to yourself?",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
        {
          question:
            "How often do you find yourself talking too much when you are in social situations?",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
        {
          question:
            "When you’re in a conversation, how often do you find yourself finishing the sentences of the people you are talking to, before they can finish them themselves?",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
        {
          question:
            "How often do you have difficulty waiting your turn in situations when turn taking is required?",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
        {
          question: "How often do you interrupt others when they are busy?",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
      ],
    },
  ],
};
