import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";

type SleepBoxProps = {
  control: any;
  watch: any;
};

const SleepBox: React.FC<SleepBoxProps> = ({ control, watch }) => {
  return (
    <Box mt={3} mb={3}>
      <Typography variant="h6">Sleep</Typography>
      <Controller
        name="sleep.hours"
        control={control}
        defaultValue={0}
        render={({ field }) => (
          <TextField
            sx={{ width: 325 }}
            {...field}
            type="number"
            label="How many hours of sleep do you get on average?"
            variant="outlined"
            margin="dense"
          />
        )}
      />
      <Controller
        name="sleep.difficulty"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Do you have difficulty falling asleep?"
            />
          </FormControl>
        )}
      />
      <Controller
        name="sleep.difficultyStaying"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Do you have difficulty staying asleep?"
            />
          </FormControl>
        )}
      />
    </Box>
  );
};

export default SleepBox;
