import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  TextField,
  MenuItem,
  Typography,
  Button,
  Snackbar,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Tab,
  Tabs,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
} from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

import { SelectChangeEvent } from "@mui/material";
import { generateAllForms } from "../utils/pdfUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAllFormData,
  resetInfo,
  resetPatientIntakeData,
  updateInfo,
} from "../redux/formSlice";
import { RootState } from "../redux/store";
import { SectionResponse } from "../types";

const SettingsPage: React.FC = () => {
  const [snackbarMesssage, setSnackbarMessage] = useState<string>("");
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isProxy, setIsProxy] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [severity, setSeverity] = useState<
    "success" | "error" | "info" | "warning" | undefined
  >("success");
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();

  const { user } = useAuth();
  const userId = user?.uid;

  const { info, forms } = useSelector((state: RootState) => state.form);
  console.log("Settings page info", info);

  const dataMap = new Map<string, SectionResponse[]>();
  Object.keys(forms).forEach((key) => {
    const value = forms[key];
    dataMap.set(key, value);
  });

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleSnackbarClose = () => {
    setOpen(false);
  };

  const showSuccessSnackbar = (message: string) => {
    setSnackbarMessage(message);
    setSeverity("success");
    setOpen(true);
  };

  const showFailSnackbar = (message: string) => {
    setSnackbarMessage(message);
    setSeverity("error");
    setOpen(true);
  };

  const handleConfirm = async () => {
    console.log("Resetting data...");
    if (userId) {
      console.log("Resetting data for user:", userId);
      dispatch(resetAllFormData());
      showSuccessSnackbar("Data reset successfully!");
      setDialogOpen(false);
      dispatch(resetAllFormData());
    } else {
      console.log("Error resetting data. Please try again later. NO USERID");
      showFailSnackbar("Error resetting data. Please try again later.");
      setDialogOpen(false);
    }
    setDialogOpen(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const handleProxyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsProxy(e.target.checked);
    if (!e.target.checked) {
      dispatch(resetInfo());
    }
  };

  const handleRelationshipChange = (e: SelectChangeEvent<string>) => {
    const newInfo = {
      ...info,
      relationship: e.target.value,
    };
    dispatch(
      updateInfo({
        info: newInfo,
      })
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newInfo = {
      ...info,
      [name]: value,
    };
    dispatch(
      updateInfo({
        info: newInfo,
      })
    );
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic
    if (!userId) return;
    console.log("Info submitted:", info);
    setSnackbarMessage("Error saving settings. Please try again later.");
    setOpen(true);
    return;
  };

  const handleResetAllAnswers = () => {
    setDialogOpen(true);
  };

  const handleResetPatientIntake = async () => {
    if (!userId) return;

    dispatch(resetPatientIntakeData());
    showSuccessSnackbar("Patient Intake form reset successfully!");
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          mt: 4,
          p: 2,
          border: "1px solid #ccc",
          borderRadius: "8px",
        }}
      >
        <Typography variant="h4" align="center">
          Settings
        </Typography>

        <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab label="Info" />
          <Tab label="Password" />
          <Tab label="Forms" />
        </Tabs>

        <Divider sx={{ my: 2 }} />

        {tabIndex === 0 && (
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {isProxy && (
              <TextField
                label={"Name"}
                name="proxyName"
                value={info.proxyName}
                onChange={handleChange}
                fullWidth
              />
            )}
            <TextField
              label={isProxy ? "Patient Name" : "Name"}
              name="name"
              value={info.name}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label={isProxy ? "Patient Age" : "Age"}
              name="age"
              type="number"
              value={info.age}
              onChange={handleChange}
              fullWidth
            />
            {isProxy && (
              <>
                <FormControl fullWidth>
                  <InputLabel id="relationship-label">
                    Relationship to Patient
                  </InputLabel>
                  <Select
                    labelId="relationship-label"
                    label="Relationship to Patient"
                    name="relationshipToPatient"
                    value={info.relationship}
                    onChange={handleRelationshipChange}
                  >
                    <MenuItem value="Parent">Parent</MenuItem>
                    <MenuItem value="Guardian">Guardian</MenuItem>
                    <MenuItem value="Sibling">Sibling</MenuItem>
                    <MenuItem value="Spouse">Spouse</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  label={"Time spent with patient in a typical week (hrs)"}
                  name="time"
                  type="number"
                  value={info.time}
                  onChange={handleChange}
                  fullWidth
                />
              </>
            )}
            <FormControlLabel
              control={
                <Checkbox checked={isProxy} onChange={handleProxyChange} />
              }
              label="I am completing these forms for someone else"
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Save Settings
            </Button>
          </Box>
        )}

        {tabIndex === 1 && (
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <TextField
              label="Current Password"
              name="currentPassword"
              type="password"
              value={currentPassword}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="New Password"
              name="newPassword"
              type="password"
              value={newPassword}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Confirm New Password"
              name="confirmNewPassword"
              type="password"
              value={confirmNewPassword}
              onChange={handleChange}
              fullWidth
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Save New Password
            </Button>
          </Box>
        )}

        {tabIndex === 2 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => generateAllForms(dataMap, info)}
            >
              Generate PDF
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleResetAllAnswers}
            >
              Reset All Forms
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleResetPatientIntake}
            >
              Reset Patient Intake
            </Button>
          </Box>
        )}

        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMesssage}
          </Alert>
        </Snackbar>
      </Box>

      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to reset the data? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SettingsPage;
