import React from 'react';
import { Controller } from 'react-hook-form';
import { RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';

type RadioQuestionProps = {
  control: any;
  name: string;
  question: string;
  options?: string[];
  response: number | string | Date | boolean | string[][];
};

const RadioQuestion: React.FC<RadioQuestionProps> = ({
  control,
  name,
  question,
  options,
  response,
}) => {
  return (
    <div>
      <Typography variant="h6">{question}</Typography>
      <Controller
        name={name}
        control={control}
        defaultValue={response}
        render={({ field }) => (
          <RadioGroup
            {...field}
            value={field.value}
            onChange={(event) => field.onChange(parseInt(event.target.value))}
          >
            {options &&
              options.map((option, idx) => (
                <FormControlLabel
                  key={option}
                  value={idx.toString()} // Store the index as the value
                  control={<Radio />}
                  label={option}
                />
              ))}
          </RadioGroup>
        )}
      />
    </div>
  );
};

export default RadioQuestion;