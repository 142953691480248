import { QuestionsData } from "../types";

const defaultOptions = ["Never", "Rarely", "Sometimes", "Often", "Always"];

export const questions: QuestionsData = {
  title:
    "LEVEL 2—Depression—Adult, PROMIS Emotional Distress—Depression—Short Form",
  formName: "cc_level2_depression",
  instructions:
    "On the DSM-5-TR Level 1 cross-cutting questionnaire that you just completed, you indicated that during the past 2 weeks you (the individual receiving care) have been bothered by “no interest or pleasure in doing things” and/or “feeling down, depressed, or hopeless” at a mild or greater level of severity. The questions below ask about these feelings in more detail and especially how often you (the individual receiving care) have been bothered by a list of symptoms during the past 7 days. Please respond to each item by marking ( or x) one box per row.",
  sections: [
    {
      sectionId: "section0",
      sectionNumeral: "I",
      label: "Depression",
      score: 0,
      scoreThresholds: [
        { minimum: 0, maximum: 17, evaluation: "None to slight" },
        { minimum: 18, maximum: 22, evaluation: "Mild" },
        { minimum: 23, maximum: 32, evaluation: "Moderate" },
        { minimum: 33, maximum: 40, evaluation: "Severe" },
      ], // T-scores converted to raw score, assuming all 8 questions answered
      scoreExplanation:
        "Sum of all answers with (1=never; 2=rarely; 3=sometimes; 4=often; and 5=always)",
      instructions: "In the past SEVEN (7) DAYS....",
      questions: [
        {
          question: "I felt worthless.",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "I felt that I had nothing to look forward to.",
          response: 0,
          options: defaultOptions,
          type: "toggle_group",
        },
        {
          question: "I felt helpless.",
          response: 0,
          options: defaultOptions,
          type: "toggle_group",
        },
        {
          question: "I felt sad.",
          response: 0,
          options: defaultOptions,
          type: "toggle_group",
        },
        {
          question: "I felt like a failure.",
          response: 0,
          options: defaultOptions,
          type: "toggle_group",
        },
        {
          question: "I felt depressed.",
          response: 0,
          options: defaultOptions,
          type: "toggle_group",
        },
        {
          question: "I felt unhappy.",
          response: 0,
          options: defaultOptions,
          type: "toggle_group",
        },
        {
          question: "I felt hopeless.",
          response: 0,
          options: defaultOptions,
          type: "toggle_group",
        },
      ],
    },
  ],
  scores: [
    { label: "Total/Partial Raw Score", type: "number" },
    { label: "Prorated Total Raw Score", type: "number" },
    { label: "T-Score", type: "number" },
  ],
  disclaimer:
    "©2008-2012 PROMIS Health Organization (PHO) and PROMIS Cooperative Group. This material can be reproduced without permission by clinicians for use with their patients. Any other use, including electronic use, requires written permission of the PHO.",
};
