import { TscoresProps, isTscoresPropKey, tscores } from "../data/tscores";
import {
  Level1Section,
  Level2Section,
  QuestionsData,
  SectionResponse,
} from "../types";

// Max Threshold: score is the maximum response value from the section
// Agg Threshold: score is the sum of all responses in the section

export const updateScores = (
  sectionResponsesArray: SectionResponse[],
  questionsData: QuestionsData
): QuestionsData => {
  const formName = questionsData.formName;

  switch (formName) {
    case "cc_level1":
      sectionResponsesArray.forEach((sectionResponses) => {
        const sections = questionsData.sections as Level1Section[];
        const section = sections.find(
          (section: Level1Section) =>
            section.sectionId === `${sectionResponses.sectionId}`
        ) as Level1Section;
        // score is going to be the maxixum response value from the section

        const score = Math.max(...sectionResponses.questions);
        section.score = score;
      });
      break;

    case "gad7":
    case "cc_level2_ocd":
    case "cc_level2_somatic":
      sectionResponsesArray.forEach((sectionResponses) => {
        const sections = questionsData.sections as Level2Section[];
        const section = sections.find(
          (section: Level2Section) =>
            section.sectionId === sectionResponses.sectionId
        ) as Level2Section;
        // score is going to be the sum of all responses in the section
        const score = sectionResponses.questions.reduce((a, b) => a + b, 0);
        // console.log("Section", section.label, "score", score);
        section.score = score;
        // console.log(
        //   `Section ${section.label} score ${score} Evaluation ${getEvaluation(
        //     score,
        //     section.scoreThresholds
        //   )}`
        // );
      });
      break;

    case "cc_level2_anger":
    case "cc_level2_anxiety":
    case "cc_level2_depression":
    case "cc_level2_sleep":
    case "cc_level2_mania":
      sectionResponsesArray.forEach((sectionResponses) => {
        const sections = questionsData.sections as Level2Section[];
        const section = sections.find(
          (section) => section.sectionId === sectionResponses.sectionId
        ) as Level2Section;
        // score is going to be the sum of all responses in the section, but plus 1, so response of 0 is 1 point
        const score = sectionResponses.questions.reduce(
          (total, question, index) => {
            const inv = isQuestionScoringInverted(
              formName,
              section.sectionId,
              index
            );
            console.log(
              `Checking if ${formName} and question ${index} is inverted = ${inv} for ${question}`
            );
            const value = isQuestionScoringInverted(
              formName,
              section.sectionId,
              index
            )
              ? 5 - question // Invert the response
              : question + 1; // Regular scoring, adding 1 to the response
            return total + value;
          },
          0
        );

        console.log("questionsData", questionsData);

        if (isTscoresPropKey(formName)) {
          const tscore = tscores[formName].find(
            (tscore) => tscore.raw === score
          ) || { tScore: 0 };
          section.tscore = tscore.tScore;
        }
      });
      break;

    default:
      console.log("Unknown form", formName);
  }

  return questionsData;
};

export const getEvaluation = (score: number, scoreThresholds: any) => {
  for (const threshold of scoreThresholds) {
    if (score >= threshold.minimum && score <= threshold.maximum) {
      return threshold.evaluation;
    }
  }
  return "Score out of range";
};

export const isLevel2SectionArray = (
  sections: Level1Section[] | Level2Section[]
): sections is Level2Section[] => {
  const l2sections = sections as Level2Section[];
  if (!l2sections) {
    return false;
  } else {
    return l2sections[0]?.scoreThresholds !== undefined;
  }
};

const sectionMeetsThreshold = (
  questionsData: QuestionsData,
  formName: string
): boolean => {
  if (isLevel2SectionArray(questionsData.sections)) {
    return false;
  } else {
    const sections: Level1Section[] = questionsData.sections as Level1Section[];
    if (!sections) {
      return false;
    }
    const section = sections.find((section) => section.form === formName);
    if (section) {
      // console.log(
      //   "Section",
      //   section.label,
      //   "score",
      //   section.score,
      //   "threshold",
      //   section.threshold
      // );
    }
    if (section && section.threshold !== undefined) {
      return section.score >= section.threshold;
    }
  }

  return false;
};

export const level2_form_required = (
  level1questionsData: QuestionsData,
  formName: string
) => {
  return sectionMeetsThreshold(level1questionsData, formName);
};

export const isQuestionScoringInverted = (
  formName: string,
  sectionId: string,
  questionIdx: number
) => {
  if (formName === "cc_level2_sleep") {
    return (
      questionIdx === 1 ||
      questionIdx === 2 ||
      questionIdx === 6 ||
      questionIdx === 7
    );
  }
  return false;
};

export const getFormProratedScore = (
  formName: string,
  score: number,
  questionsAnswered: number
) => {
  const totalQuestions = getNumTotalQuestions(formName);
  return getProratedScore(score, totalQuestions, questionsAnswered);
};

const getProratedScore = (
  score: number,
  totalQuestions: number,
  questionsAnswered: number
) => {
  return Math.ceil((score / questionsAnswered) * totalQuestions);
};

export const getNumTotalQuestions = (formName: string) => {
  switch (formName) {
    case "cc_level2_anxiety":
      return 7;
    case "cc_level2_depression":
    case "cc_level2_sleep":
      return 8;
    case "cc_level2_mania":
    case "cc_level2_anger":
    case "cc_level2_ocd":
      return 5;
    case "cc_level2_somatic":
      return 15;

    default:
      return 0;
  }
};
