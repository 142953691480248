import React from "react";
import { createRoot } from "react-dom/client";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { ThemeProvider } from "styled-components";
import App from "./App";
import "./index.css";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./redux/store";
import { Provider } from "react-redux";

const theme = createTheme();
const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);

  root.render(
    // <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </MuiThemeProvider>
    // </React.StrictMode>
  );
} else {
  console.error("Root element not found");
}