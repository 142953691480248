import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";

type AdditionalBoxProps = {
  control: any;
  watch: any;
  resetField: any;
};

const AdditionalBox: React.FC<AdditionalBoxProps> = ({
  control,
  watch,
  resetField,
}) => {
  const recentIllness = watch("additional.recentIllness");
  const exposure = watch("additional.exposure");

  useEffect(() => {
    if (!recentIllness) {
      resetField("additional.recentIllnessDetails");
    }
    if (!exposure) {
      resetField("additional.exposureDetails");
    }
  }, [recentIllness, exposure, resetField]);

  return (
    <Box mt={3} mb={3}>
      <Typography variant="h6">Additional Information</Typography>
      <Controller
        name="additional.recentIllness"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Do you have any recent illnesses/infections or hospitalizations?"
            />
          </FormControl>
        )}
      />
      {recentIllness && (
        <>
          <Controller
            name="additional.recentIllnessDetails"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Please describe."
                variant="outlined"
                margin="dense"
              />
            )}
          />
        </>
      )}
      <Controller
        name="additional.exposure"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Any recent significant exposure  to someone who is sick with COVID, influenza, pink eye, or other contagious disease? (within the past week, spending longer than 1 hr with the infected individual)"
            />
          </FormControl>
        )}
      />
      {exposure && (
        <>
          <Controller
            name="additional.exposureDetails"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Please describe."
                variant="outlined"
                margin="dense"
              />
            )}
          />
        </>
      )}
    </Box>
  );
};

export default AdditionalBox;
