import { QuestionsData, SectionResponse } from "../types";
import { questions as level1Questions } from "../data/cc1_questions";
import { questions as angerQuestions } from "../data/anger_questions";
import { questions as anxietyQuestions } from "../data/anxiety_questions";
import { questions as depressionQuestions } from "../data/depression_questions";
import { questions as maniaQuestions } from "../data/mania_questions";
import { questions as ocdQuestions } from "../data/ocd_questions";
import { questions as sleepQuestions } from "../data/sleep_questions";
import { questions as somaticQuestions } from "../data/somatic_questions";
import { questions as gad7Questions } from "../data/gad7_questions";
import { questions as asrsQuestions } from "../data/asrs_questions";
import { questions as mdqQuestions } from "../data/mdq_questions";
import { questions as phq9Questions } from "../data/phq9_questions";

export type FormData = {
  [key: string]: number;
};

export const prepareFormData = (
  formName: string,
  formData: FormData
): SectionResponse[] => {
  const sectionMap: { [sectionId: string]: number[] } = {};

  Object.keys(formData).forEach((key) => {
    const [sectionId, questionIdx] = key.split("-");
    const questionIndex = parseInt(questionIdx, 10);

    if (!sectionMap[sectionId]) {
      sectionMap[sectionId] = [];
    }

    sectionMap[sectionId][questionIndex] = formData[key];
  });

  return Object.keys(sectionMap).map((sectionId) => ({
    sectionId,
    questions: sectionMap[sectionId],
  }));

  // convert
  // const sectionResponses: SectionResponse[] = sections.map((section) => ({
  //   sectionId: section.sectionId,
  //   questions: section.questions.map((question) => question.response),
  // }));

  const sectionResponses: SectionResponse[] = [];

  return sectionResponses;
};

export const parseDBQuestionsData = (
  dbData: any,
  formName: string
): SectionResponse[] => {
  console.log("dbData in the parsing function", dbData);
  const sectionsMap: { [sectionId: string]: SectionResponse } = {};

  const { info, patientIntake, ...rest } = dbData;
  const formData = rest[formName] || {};

  console.log("formData in parsing", formData);

  formData.forEach((section: SectionResponse) => {
    sectionsMap[section.sectionId] = section;
  });

  Object.keys(formData).forEach((key) => {
    console.log("key)");
    const [sectionId, questionIdx] = key.split("-");
    const response = formData[key];

    if (!sectionsMap[sectionId]) {
      sectionsMap[sectionId] = {
        sectionId,
        questions: [],
      };
    }

    const questionIndex = parseInt(questionIdx, 10);
    sectionsMap[sectionId].questions[questionIndex] = response;
  });

  const sectionsArray: SectionResponse[] = Object.values(sectionsMap).map(
    (section) => ({
      ...section,
      questions: section.questions.map((question) =>
        question !== undefined ? question : 0
      ),
    })
  );

  return sectionsArray;
};

const deepCopy = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

export const getQuestionsJson = (formName: string): QuestionsData => {
  switch (formName) {
    case "cc_level1":
      return deepCopy(level1Questions);
    case "cc_level2_anger":
      return deepCopy(angerQuestions);
    case "cc_level2_anxiety":
      return deepCopy(anxietyQuestions);
    case "cc_level2_depression":
      return deepCopy(depressionQuestions);
    case "cc_level2_mania":
      return deepCopy(maniaQuestions);
    case "cc_level2_ocd":
      return deepCopy(ocdQuestions);
    case "cc_level2_sleep":
      return deepCopy(sleepQuestions);
    case "cc_level2_somatic":
      return deepCopy(somaticQuestions);
    case "gad7":
      return deepCopy(gad7Questions);
    case "asrs":
      return deepCopy(asrsQuestions);
    case "mdq":
      return deepCopy(mdqQuestions);
    case "phq9":
      return deepCopy(phq9Questions);
    default:
      return deepCopy(level1Questions);
  }
};

export const formsList = [
  "patientIntake",
  "cc_level1",
  "cc_level2_anger",
  "cc_level2_anxiety",
  "cc_level2_depression",
  "cc_level2_mania",
  "cc_level2_ocd",
  "cc_level2_sleep",
  "cc_level2_somatic",
  "gad7",
  "asrs",
  "mdq",
  "phq9",
];
