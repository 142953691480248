import { QuestionsData } from "../types";

const defaultOptions = ["Never", "Rarely", "Sometimes", "Often", "Always"];

export const questions: QuestionsData = {
  title: "PROMIS Emotional Distress—Anxiety—Short Form",
  formName: "cc_level2_anxiety",
  instructions:
    "On the DSM-5-TR Level 1 cross-cutting questionnaire that you just completed, you indicated that during the past 2 weeks you (individual receiving care) have been bothered by “feeling nervous, anxious, frightened, worried, or on edge”, “feeling panic or being frightened”, and/or “avoiding situations that make you anxious” at a mild or greater level of severity. The questions below ask about these feelings in more detail and especially how often you (individual receiving care) have been bothered by a list of symptoms during the past 7 days. Please respond to each item by marking ( or x) one box per row.",
  sections: [
    {
      sectionId: "section0",
      sectionNumeral: "I",
      label: "Anxiety",
      score: 0,
      scoreThresholds: [
        { minimum: 0, maximum: 16, evaluation: "None to slight" },
        { minimum: 17, maximum: 19, evaluation: "Mild" },
        { minimum: 20, maximum: 27, evaluation: "Moderate" },
        { minimum: 28, maximum: 35, evaluation: "Severe" },
      ], // T-scores converted to raw score, assuming all 7 questions answered
      scoreExplanation:
        "Sum of all answers with (1=never; 2=rarely; 3=sometimes; 4=often; and 5=always)",
      instructions: "In the past SEVEN (7) DAYS....",
      questions: [
        {
          question: "I felt fearful.",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "I felt anxious.",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "I felt worried.",
          response: 0,
          type: "toggle_group",
          options: defaultOptions,
        },
        {
          question:
            "I found it hard to focus on anything other than my anxiety.",
          response: 0,
          options: defaultOptions,
          type: "toggle_group",
        },
        {
          question: "I felt nervous.",
          response: 0,
          options: defaultOptions,
          type: "toggle_group",
        },
        {
          question: "I felt uneasy.",
          response: 0,
          options: defaultOptions,
          type: "toggle_group",
        },
        {
          question: "I felt tense.",
          response: 0,
          options: defaultOptions,
          type: "toggle_group",
        },
      ],
    },
  ],
  scores: [
    {
      label: "Total/Partial Raw Score",
      type: "number",
      name: "total_partial_raw_score",
    },
    {
      label: "Prorated Total Raw Score",
      type: "number",
      name: "prorated_total_raw_score",
    },
    {
      label: "T-Score",
      type: "number",
      name: "t_score",
    },
  ],
  disclaimer:
    "©2008-2012 PROMIS Health Organization (PHO) and PROMIS Cooperative Group. This material can be reproduced without permission by clinicians for use with their patients. Any other use, including electronic use, requires written permission of the PHO.",
};
