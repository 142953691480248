import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { firebase, auth } from "../firebaseConfig";

export function useAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setIsEmailVerified(currentUser.emailVerified); // Update verification status
      } else {
        setUser(null);
        setIsEmailVerified(false); // Reset verification status when signed out
      }
      setIsLoading(false);
    });

    return unsubscribe;
  }, []);

  return { user, isLoading, isEmailVerified };
}
