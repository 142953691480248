import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import Level1 from "./pages/Level1";
import Anger from "./pages/Anger";
import Mania from "./pages/Mania";
import Depression from "./pages/Depression";
import Sleep from "./pages/Sleep";
import GAD7 from "./pages/GAD7";
import OCD from "./pages/OCD";
import PatientIntake from "./pages/PatientIntake";
import Anxiety from "./pages/Anxiety";
import Somatic from "./pages/Somatic";

import SignupPage from "./pages/SignUp";
import LoginPage from "./pages/SignIn";
import SettingsPage from "./pages/Settings";
import ForgotPassword from "./pages/ForgotPassword";
import PDFTest from "./pages/PDFTest";
import { RootState } from "./redux/store";
import { useSelector } from "react-redux";
import { useAuth } from "./hooks/useAuth";
import SendFormsPage from "./pages/SendForms";
import MDQ from "./pages/MDQ";
import PHQ from "./pages/PHQ";
import ASRS from "./pages/ASRS";
import LandingPage from "./pages/LandingPage";
import WizardPage from "./pages/WizardPage";
import HelpPage from "./pages/HelpPage";

const App: React.FC = () => {
  const formState = useSelector((state: RootState) => state.form);

  const { user } = useAuth();
  const userId = user?.uid || "";

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/help" element={<HelpPage />} />
          <Route path="/checklist" element={<WizardPage />} />
          <Route path="/cross-cutting-level1" element={<Level1 />} />
          <Route path="/cross-cutting-level2-anger" element={<Anger />} />
          <Route path="/cross-cutting-level2-anxiety" element={<Anxiety />} />
          <Route path="/cross-cutting-level2-mania" element={<Mania />} />
          <Route
            path="/cross-cutting-level2-depression"
            element={<Depression />}
          />
          <Route path="/cross-cutting-level2-sleep" element={<Sleep />} />
          <Route path="/gad7" element={<GAD7 />} />
          <Route path="/mdq" element={<MDQ />} />
          <Route path="/phq9" element={<PHQ />} />
          <Route path="/asrs" element={<ASRS />} />
          <Route path="/cross-cutting-level2-ocd" element={<OCD />} />
          <Route path="/patient-intake" element={<PatientIntake />} />
          <Route path="/cross-cutting-level2-somatic" element={<Somatic />} />

          <Route path="/signup" element={<SignupPage />} />
          <Route path="/signin" element={<LoginPage />} />

          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/pdf" element={<PDFTest />} />
          <Route path="/send-form" element={<SendFormsPage />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
