import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField, Typography } from '@mui/material';

type TextQuestionProps = {
  control: any;
  name: string;
  question: string;
  response: string;
};

const TextQuestion: React.FC<TextQuestionProps> = ({
  control,
  name,
  question,
  response
}) => {
  return (
    <div>
      <Typography variant="h6">{question}</Typography>
      <Controller
        name={name}
        control={control}
        defaultValue={response}
        render={({ field }) => (
          <TextField
            {...field}
            value={field.value}
            onChange={(event) => field.onChange(event.target.value)}
          />
        )}
      />
    </div>
  );
};

export default TextQuestion;
