import React from "react";
import {
  Container,
  Typography,
  Button,
  AppBar,
  Toolbar,
  CssBaseline,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  const handleStartClick = () => {
    navigate("/patient-intake");
  };

  const styles = {
    appBar: {
      marginBottom: "32px",
    },
    logo: {
      height: "50px",
      marginRight: "16px",
    },
    heroContent: {
      padding: "64px 0 48px",
      textAlign: "center",
    },
    startButton: {
      marginTop: "32px",
    },
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Box sx={styles.heroContent}>
          <Typography variant="h3" component="h1" gutterBottom>
            Welcome to Our Patient Intake Portal
          </Typography>
          <Typography
            variant="h5"
            component="p"
            color="textSecondary"
            paragraph
          >
            Please start the intake process by clicking the button below.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={styles.startButton}
            onClick={handleStartClick}
          >
            Start Your Intake Process
          </Button>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default LandingPage;
