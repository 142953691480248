import SurveyForm from "./SurveyForm";
import { useAuth } from "../hooks/useAuth";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { FormProvider, useForm } from "react-hook-form";

type SurveyFormPageProps = {
  formName: string;
};

const SurveyFormPage: React.FC<SurveyFormPageProps> = ({ formName }) => {
  const { user } = useAuth();
  const userId = user?.uid || "";
  const methods = useForm();

  const allForms = useSelector((state: RootState) => state.form.forms);
  const formState = allForms[formName];

  return (
    <FormProvider {...methods}>
      <SurveyForm userId={userId} formName={formName} formState={formState} />
    </FormProvider>
  );
};

export default SurveyFormPage;
