import React from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  CssBaseline,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const faqDetails = [
  {
    question: "How is my information kept private?",
    answer:
      "Your information is not stored permanently. It is securely deleted after submitting the forms to your provider to ensure your privacy. We adhere to all relevant privacy laws and regulations to protect your personal information.",
  },
  {
    question: "How are the forms used?",
    answer:
      "The forms you fill out provide crucial information that helps your clinician better understand your health needs and tailor treatment plans accordingly. This ensures that you receive the most effective and personalized care possible.",
  },
  {
    question: "Who can I contact for questions or problems with the forms?",
    answer:
      "If you have any questions or encounter any problems with the forms, please contact Leah Dymock, PMHNP, at 503-521-6829. She will be happy to assist you with any concerns you may have.",
  },
];

const HelpPage: React.FC = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Box mt={4} mb={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Frequently Asked Questions
          </Typography>
          {faqDetails.map((faq, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography paragraph>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default HelpPage;
