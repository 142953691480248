import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  Alert,
  Snackbar,
} from "@mui/material";

import { useAuth } from "../hooks/useAuth";
import { Information, SectionResponse } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useSnackbar } from "../utils/SnackbarContext";
import { generateAllForms } from "../utils/pdfUtils";

const initializeSettings = (settings: Partial<Information>): Information => {
  return {
    name: settings?.name || "",
    proxyName: settings?.proxyName || "",
    age: settings?.age ?? "",
    relationship: settings?.relationship || "",
    time: settings?.time || "",
    email: settings?.email || "",
  };
};

const SendFormsPage: React.FC = () => {
  const { user } = useAuth();
  const userId = user?.uid;

  const { info } = useSelector((state: RootState) => state.form);
  console.log("Send forms page info", info);
  const { showSnackbar } = useSnackbar();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const data = useSelector((state: RootState) => state.form.forms);
  const dataMap = new Map<string, SectionResponse[]>();

  // convert data to 'Map<string, SectionResponse[]>'
  Object.keys(data).forEach((key) => {
    const value = data[key];
    dataMap.set(key, value);
  });

  const currentDateString = new Date().toLocaleDateString();

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Send Forms
        </Typography>

        <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
          <strong>Patient Name:</strong> {info?.name} <br />
          <strong>Date:</strong> {currentDateString} <br />
          <strong>Summary:</strong>{" "}
          {`Forms have been filled out for patient ${info?.name} and are ready for submission.`}
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={() => generateAllForms(dataMap, info)}
        >
          Generate PDF
        </Button>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}></Box>
      </Box>
    </Container>
  );
};

export default SendFormsPage;