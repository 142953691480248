import { QuestionsData } from "../types";

const defaultOptions = [
  "Not at all",
  "Slight or rare, less than a day or two",
  "Mild or several days",
  "Moderate or more than half the days",
  "Severe or nearly every day",
];

export const questions: QuestionsData = {
  title: "DSM-5-TR Self-Rated Level 1 Cross-Cutting Symptom Measure—Adult",
  formName: "cc_level1",
  instructions:
    "The questions below ask about things that might have bothered you. For each question, select the number that best describes how much (or how often) you have been bothered by each problem during the past TWO (2) WEEKS.",
  sections: [
    {
      sectionId: "section0",
      label: "Section I", //"Depression",
      form: "cc_level2_depression",
      sectionNumeral: "I",
      score: 0,
      threshold: 2, //any score of 2 or more in this section will trigger a follow-up
      questions: [
        {
          question: "Little interest or pleasure in doing things?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Feeling down, depressed, or hopeless?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
      ],
    },
    {
      sectionId: "section1",
      label: "Section II", //"Anger",
      form: "cc_level2_anger",
      sectionNumeral: "II",
      score: 0,
      threshold: 2, //any score of 2 or more in this section will trigger a follow-up
      questions: [
        {
          question: "Feeling more irritated, grouchy, or angry than usual?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
      ],
    },
    {
      sectionId: "section2",
      label: "Section III", //"Mania",
      form: "cc_level2_mania",
      score: 0,
      threshold: 2,
      sectionNumeral: "III",
      questions: [
        {
          question: "Sleeping less than usual, but still have a lot of energy?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question:
            "Starting lots more projects than usual or doing more risky things than usual?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
      ],
    },
    {
      sectionId: "section3",
      label: "Section IV", //"Anxiety",
      form: "cc_level2_anxiety",
      score: 0,
      threshold: 2, //any score of 2 or more in this section will trigger a follow-up
      sectionNumeral: "IV",
      questions: [
        {
          question:
            "Feeling nervous, anxious, frightened, worried, or on edge?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Feeling panic or being frightened?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Avoiding situations that make you anxious?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
      ],
    },
    {
      sectionId: "section4",
      label: "Section V", //"Somatic Symptoms",
      form: "cc_level2_somatic",
      threshold: 2, //any score of 2 or more in this section will trigger a follow-up
      score: 0,
      sectionNumeral: "V",
      questions: [
        {
          question:
            "Unexplained aches and pains (e.g., head, back, joints, abdomen, legs)?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question:
            "Feeling that your illnesses are not being taken seriously enough?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
      ],
    },
    {
      sectionId: "section5",
      label: "Section VI", //"Suicidal Ideation",
      form: "none",
      sectionNumeral: "VI",
      score: 0,
      threshold: 1, //any score of 1 or more in this section will trigger a follow-up
      questions: [
        {
          question: "Thoughts of actually hurting yourself?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
      ],
    },
    {
      sectionId: "section6",
      label: "Section VII", //"Psychosis",
      form: "none",
      sectionNumeral: "VII",
      score: 0,
      threshold: 1, //any score of 1 or more in this section will trigger a follow-up
      questions: [
        {
          question:
            "Hearing things other people couldn’t hear, such as voices even when no one was around?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question:
            "Feeling that someone could hear your thoughts, or that you could hear what another person was thinking?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
      ],
    },
    {
      sectionId: "section7",
      label: "Section VIII", //"Sleep Problems",
      form: "cc_level2_sleep",
      score: 0,
      threshold: 2, //any score of 2 or more in this section will trigger a follow-up
      sectionNumeral: "VIII",
      questions: [
        {
          question:
            "Problems with sleep that affected your sleep quality overall?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
      ],
    },
    {
      sectionId: "section8",
      label: "Section IX", //"Memory",
      score: 0,
      form: "none",
      threshold: 2, //any score of 2 or more in this section will trigger a follow-up
      sectionNumeral: "IX",
      questions: [
        {
          question:
            "Problems with memory (e.g., learning new information) or with location (e.g., finding your way home)?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
      ],
    },
    {
      sectionId: "section9",
      label: "Section X", //"Repetitive Thoughts and Behaviors",
      form: "cc_level2_ocd",
      sectionNumeral: "X",
      score: 0,
      threshold: 2, //any score of 2 or more in this section will trigger a follow-up
      questions: [
        {
          question:
            "Unpleasant thoughts, urges, or images that repeatedly enter your mind?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question:
            "Feeling driven to perform certain behaviors or mental acts over and over again?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
      ],
    },
    {
      sectionId: "section10",
      label: "Section XI", //"Dissociation",
      form: "none",
      score: 0,
      threshold: 2, //any score of 2 or more in this section will trigger a follow-up
      sectionNumeral: "XI",
      questions: [
        {
          question:
            "Feeling detached or distant from yourself, your body, your physical surroundings, or your memories?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
      ],
    },
    {
      sectionId: "section11",
      label: "Section XII", //"Dissociation",
      form: "none",
      score: 0,
      threshold: 2, //any score of 2 or more in this section will trigger a follow-up
      sectionNumeral: "XII",
      questions: [
        {
          question:
            "Not knowing who you really are or what you want out of life?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question:
            "Not feeling close to other people or enjoying your relationships with them?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
      ],
    },
    {
      sectionId: "section12",
      label: "Section XIII", //"Substance use",
      form: "cc_level2_substance_abuse",
      sectionNumeral: "XIII",
      score: 0,
      threshold: 1, //any score of 1 or more in this section will trigger a follow-up
      questions: [
        {
          question:
            "Drinking at least 4 drinks of any kind of alcohol in a single day?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question:
            "Smoking any cigarettes, a cigar, or pipe, or using snuff or chewing tobacco?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question:
            "Using any of the following medicines ON YOUR OWN, that is, without a doctor’s prescription, in greater amounts or longer than prescribed [e.g., painkillers (like Vicodin), stimulants (like Ritalin or Adderall), sedatives or tranquilizers (like sleeping pills or Valium), or drugs like marijuana, cocaine or crack, club drugs (like ecstasy), hallucinogens (like LSD), heroin, inhalants or solvents (like glue), or methamphetamine (like speed)]?",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
      ],
    },
  ],
};
