import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Button,
  CssBaseline,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  ListItemButton,
} from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import HomeIcon from "@mui/icons-material/Home";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import styled, { useTheme } from "styled-components";
import { PatientIntakeFormValues } from "./PatientIntake";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import useQuestionsData from "../hooks/useQuestionsData";
import { formsList } from "../utils/dbUtils";
import { level2_form_required } from "../utils/formScoringUtils";
import { generateAllForms } from "../utils/pdfUtils";
import { SectionResponse } from "../types";

type FormLink = {
  text: string;
  href: string;
  icon?: React.ReactNode;
  form?: string;
};

const linkMap: FormLink[] = [
  {
    text: "Home",
    href: "/",
    icon: <HomeIcon />,
    form: "N/A",
  },
  {
    text: "Patient Intake",
    href: "/patient-intake",
    icon: <ContentPasteIcon />,
    form: "patientIntake",
  },
  {
    text: "Level 1 Form",
    href: "/cross-cutting-level1",
    icon: <ContentPasteIcon />,
    form: "cc_level1",
  },
  {
    text: "Anger Form",
    href: "/cross-cutting-level2-anger",
    icon: <ContentPasteIcon />,
    form: "cc_level2_anger",
  },
  {
    text: "Anxiety Form",
    href: "/cross-cutting-level2-anxiety",
    icon: <ContentPasteIcon />,
    form: "cc_level2_anxiety",
  },
  {
    text: "Depression Form",
    href: "/cross-cutting-level2-depression",
    icon: <ContentPasteIcon />,
    form: "cc_level2_depression",
  },
  {
    text: "Mania Form",
    href: "/cross-cutting-level2-mania",
    icon: <ContentPasteIcon />,
    form: "cc_level2_mania",
  },
  {
    text: "OCD Form",
    href: "/cross-cutting-level2-ocd",
    icon: <ContentPasteIcon />,
    form: "cc_level2_ocd",
  },
  {
    text: "Sleep Form",
    href: "/cross-cutting-level2-sleep",
    icon: <ContentPasteIcon />,
    form: "cc_level2_sleep",
  },
  {
    text: "Somatic Symptoms Form",
    href: "/cross-cutting-level2-somatic",
    icon: <ContentPasteIcon />,
    form: "cc_level2_somatic",
  },
  {
    text: "Gad 7 Anxiety",
    href: "/gad7",
    icon: <ContentPasteIcon />,
    form: "gad7",
  },
  {
    text: "ASRS",
    href: "/asrs",
    icon: <ContentPasteIcon />,
    form: "asrs",
  },
  {
    text: "PHQ-9",
    href: "/phq9",
    icon: <ContentPasteIcon />,
    form: "phq9",
  },
  {
    text: "MDQ",
    href: "/mdq",
    icon: <ContentPasteIcon />,
    form: "mdq",
  },
];

const showPage = (
  form: string,
  questionsData: any,
  intake: PatientIntakeFormValues
) => {
  if (form === "cc_level1" || form === "patientIntake") {
    return true;
  }

  switch (form) {
    case "cc_level2_anger":
    case "cc_level2_anxiety":
    case "cc_level2_depression":
    case "cc_level2_mania":
    case "cc_level2_ocd":
    case "cc_level2_sleep":
    case "cc_level2_somatic":
      return level2_form_required(questionsData, form);
    case "gad7":
      return (
        intake?.personalHistory_psychiatric?.includes("anxiety") ||
        intake?.familyHistory?.anxiety?.length > 0 ||
        false
      );
    case "asrs":
      return (
        intake?.personalHistory_psychiatric?.includes("adhd") ||
        intake?.familyHistory?.adhd?.length > 0 ||
        false
      );
    case "phq9":
      return (
        intake?.personalHistory_psychiatric?.includes("depression") ||
        intake?.familyHistory?.depression?.length > 0 ||
        false
      );
    case "mdq":
      return (
        intake?.personalHistory_psychiatric?.includes("bipolar") ||
        intake?.familyHistory?.bipolar?.length > 0 ||
        false
      );
    default:
      return false;
  }
};

const FormsChecklist: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const intake: PatientIntakeFormValues = useSelector(
    (state: RootState) => state.form.patientIntake
  );

  const { info, forms } = useSelector((state: RootState) => state.form);
  console.log("Settings page info", info);

  const dataMap = new Map<string, SectionResponse[]>();
  Object.keys(forms).forEach((key) => {
    const value = forms[key];
    dataMap.set(key, value);
  });

  const formsCompleted = Object.keys(forms);

  const [completedForms, setCompletedForms] = useState<any[]>([]);
  const [requiredForms, setRequiredForms] = useState<any[]>([]);

  const navigate = useNavigate();

  const location = useLocation();

  const { questionsData } = useQuestionsData("cc_level1");

  useEffect(() => {
    const requiredForms = formsList.filter((form) => {
      return showPage(form, questionsData, intake);
    });
    setRequiredForms(requiredForms);
    const completedForms = requiredForms.filter((form) => {
      if (form === "patientIntake" && Object.keys(intake).length > 0) {
        return true;
      }
      return (
        forms && typeof form === "string" && Object.keys(forms).includes(form)
      );
    });
    setCompletedForms(completedForms);
  }, [questionsData, intake, formsList, forms]);

  const submitForms = () => {
    console.log("Submitting forms");
    generateAllForms(dataMap, info);
    handleDialogClose();
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleSubmit = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const numFormsCompleted = formsCompleted.length;
  const progress =
    numFormsCompleted === 0
      ? 0
      : (completedForms.length / requiredForms.length) * 100;

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Box mt={4} mb={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Forms Checklist
          </Typography>
        </Box>
        <List>
          {linkMap.map(({ text, href, form }) => {
            if (showPage(form || "", questionsData, intake)) {
              const complete =
                typeof form === "string" &&
                completedForms.includes(form.toString());
              return (
                <ListItemButton
                  key={text}
                  component={Link}
                  to={href}
                  selected={location.pathname === href}
                >
                  <ListItemIcon>
                    {complete ? (
                      <CheckCircleIcon sx={{ color: "green" }} />
                    ) : (
                      <RadioButtonUncheckedIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                  <Button
                    variant="contained"
                    color={complete ? "secondary" : "primary"}
                    onClick={() => navigate(href)}
                  >
                    {complete ? "Edit" : "Start"}
                  </Button>
                </ListItemButton>
              );
            }
          })}
        </List>
        <LinearProgress variant="determinate" value={progress} />
        <Typography variant="body2" color="textSecondary">
          {completedForms.length} of {requiredForms.length} forms completed
        </Typography>
        {progress === 100 && (
          <Box mt={4} textAlign="center">
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit All Forms
            </Button>
          </Box>
        )}
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Confirm Submission</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to submit all your forms? Please review your
              information before confirming.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                submitForms();
                console.log("Forms submitted");
              }}
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </React.Fragment>
  );
};

export default FormsChecklist;
