import { Controller } from "react-hook-form";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

type PersonalMedicalHistoryBoxProps = {
  control: any;
  watch: any;
  type: "psychiatric" | "medical";
};

const options = [
  { value: "adhd", label: "ADHD", type: "psychiatric" },
  { value: "allergies", label: "Allergies", type: "medical" },
  { value: "anxiety", label: "Anxiety", type: "psychiatric" },
  { value: "asthma", label: "Asthma", type: "medical" },
  { value: "autism", label: "Autism", type: "psychiatric" },
  { value: "bipolar", label: "Bipolar Disorder", type: "psychiatric" },
  { value: "cancer", label: "Cancer", type: "medical" },
  { value: "depression", label: "Depression", type: "psychiatric" },
  { value: "diabetes", label: "Diabetes", type: "medical" },
  { value: "emphysema", label: "Emphysema", type: "medical" },
  { value: "heartDisease", label: "Heart Disease", type: "medical" },
  { value: "hypertension", label: "Hypertension", type: "medical" },
  { value: "highCholesterol", label: "High Cholesterol", type: "medical" },
  { value: "hypothyroidism", label: "Hypothyroidism", type: "medical" },
  { value: "migraine", label: "Migraine", type: "medical" },
  { value: "ptsd", label: "PTSD", type: "psychiatric" },
  { value: "kidneyDisease", label: "Kidney Disease", type: "medical" },
  { value: "stroke", label: "Stroke", type: "medical" },
  {
    value: "substanceUse",
    label: "Substance Use Disorder",
    type: "psychiatric",
  },
  { value: "trauma", label: "Trauma", type: "psychiatric" },
  { value: "other", label: "Other", type: "medical" },
];

const PersonalMedicalHistoryBox: React.FC<PersonalMedicalHistoryBoxProps> = ({
  control,
  type,
  watch,
}) => {
  const MenuProps = {
    anchorOrigin: {
      vertical: "bottom" as const,
      horizontal: "left" as const,
    },
    transformOrigin: {
      vertical: "top" as const,
      horizontal: "left" as const,
    },
    getContentAnchorEl: null,
  };

  return (
    <Box mt={3} mb={3}>
      <Typography variant="h6">
        {type === "medical"
          ? "Personal Medical History"
          : "Mental Health History"}
      </Typography>
      <Typography variant="body2" gutterBottom>
        Do you have any of the following conditions? (Past or Present)
      </Typography>
      <Controller
        name={`personalHistory_${type}`}
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <FormControl margin="dense" sx={{ minWidth: 200 }}>
            <InputLabel id="conditions-label">Conditions</InputLabel>
            <Select
              {...field}
              label="Conditions"
              MenuProps={MenuProps}
              multiple
              renderValue={(selected: any[]) => {
                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected &&
                      (selected as string[]).map((value) => (
                        <Chip
                          key={value}
                          label={value.charAt(0).toUpperCase() + value.slice(1)}
                        />
                      ))}
                  </Box>
                );
              }}
              sx={{ minWidth: 200 }}
            >
              {options
                .filter((option) => option.type === type)
                .map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox checked={field.value.includes(option.value)} />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default PersonalMedicalHistoryBox;
