import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";

type SocialHistoryBoxProps = {
  control: any;
  watch: any;
  resetField: any;
};

const SocialHistoryBox: React.FC<SocialHistoryBoxProps> = ({
  control,
  watch,
  resetField,
}) => {
  const military = watch("additional.militaryService");
  useEffect(() => {
    if (!military) {
      resetField("additional.branch");
    }
  }, [military, resetField]);

  return (
    <Box mt={3} mb={3}>
      <Typography variant="h6">Social History</Typography>
      <Controller
        name="social.occupation"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            label="Occupation"
            variant="outlined"
            margin="dense"
          />
        )}
      />
      <Controller
        name="social.nightShift"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl margin="dense" sx={{ minWidth: 100 }}>
            <InputLabel>Night Shift</InputLabel>
            <Select {...field} label="Night Shift" sx={{ minWidth: 100 }}>
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name="social.education"
        control={control}
        defaultValue={""}
        render={({ field }) => (
          <FormControl margin="dense" sx={{ minWidth: 225 }}>
            <InputLabel>Highest Level of Schooling</InputLabel>
            <Select
              {...field}
              label="Highest Level of Schooling"
              sx={{ minWidth: 225 }}
            >
              <MenuItem value="none">No Formal Education</MenuItem>
              <MenuItem value="highschool">High School</MenuItem>
              <MenuItem value="GED">GED</MenuItem>
              <MenuItem value="bachelors">Bachelor's Degree</MenuItem>
              <MenuItem value="masters">Master's Degree</MenuItem>
              <MenuItem value="doctorate">Doctorate or Higher</MenuItem>
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name="social.maritalStatus"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <FormControl margin="dense" sx={{ minWidth: 150 }}>
            <InputLabel>Marital Status</InputLabel>
            <Select {...field} label="Marital Status" sx={{ minWidth: 150 }}>
              <MenuItem value="single">Single</MenuItem>
              <MenuItem value="married">Married</MenuItem>
              <MenuItem value="divorced">Divorced</MenuItem>
              <MenuItem value="widowed">Widowed</MenuItem>
            </Select>
          </FormControl>
        )}
      />
      <Controller
        name="social.numChildren"
        control={control}
        defaultValue={0}
        render={({ field }) => (
          <TextField
            {...field}
            label="Number of children"
            variant="outlined"
            inputMode="numeric"
            margin="dense"
          />
        )}
      />
      <Controller
        name="additional.militaryService"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={<Checkbox {...field} checked={field.value} />}
              label="Military Service"
            />
          </FormControl>
        )}
      />
      {military && (
        <>
          <Controller
            name="additional.branch"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ minWidth: 200 }}
                label="Which branch?"
                variant="outlined"
                margin="dense"
              />
            )}
          />
        </>
      )}
    </Box>
  );
};

export default SocialHistoryBox;
