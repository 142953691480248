import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  Button,
  CircularProgress,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import { FormData, QuestionsData, SectionResponse } from "../types";
import Section from "./Section";
import {
  getQuestionsJson,
  parseDBQuestionsData,
  prepareFormData,
} from "../utils/dbUtils";
import { useDispatch, useSelector } from "react-redux";
import { loadFormData, resetFormData } from "../redux/formSlice";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../utils/SnackbarContext";

type Props = {
  formName: string;
  userId: string;
  formState: SectionResponse[] | undefined;
};

const SurveyForm: React.FC<Props> = ({ formState, formName, userId }) => {
  const [questionsData, setQuestionsData] = useState<QuestionsData>(
    {} as QuestionsData
  );
  const [resetRequested, setResetRequested] = useState(false);
  const { control, handleSubmit, setValue } = useForm<FormData>();
  const navigate = useNavigate();

  useEffect(() => {
    if (resetRequested) {
      onReset();
      setResetRequested(false);
    }
  }, [resetRequested]);

  const sectionsMap = formState
    ? formState.reduce((acc, section) => {
        acc[section.sectionId] = section.questions;
        return acc;
      }, {} as { [key: string]: number[] })
    : {};

  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    const initialQuestions = getQuestionsJson(formName);
    setQuestionsData(initialQuestions);
  }, [formName]);

  const onReset = () => {
    dispatch(resetFormData({ formName }));
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setProcessing(true);
    try {
      const preparedData = prepareFormData(formName, data);
      dispatch(loadFormData({ formName, sections: preparedData }));
      showSnackbar("Form data saved successfully!", "success");
      navigate("/checklist");
    } catch (error) {
      showSnackbar("Error saving form data", "error");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div style={{ margin: "10px", padding: "0px" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h4">{questionsData.title}</Typography>
        <div>{questionsData.instructions}</div>
        {questionsData?.sections?.map((section, sectionIdx) => {
          const responses = sectionsMap[`section${sectionIdx}`];

          return (
            <Grid
              key={sectionIdx}
              alignContent={"center"}
              sx={{ margin: 0, marginLeft: 0 }}
            >
              <Section
                control={control}
                label={section.label}
                instructions={section.instructions || ""}
                questions={section.questions}
                responses={responses}
                sectionId={section.sectionId}
              />
            </Grid>
          );
        })}
        <Divider />
        {processing ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={onReset}>
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid>
          </Grid>
        )}
      </form>
    </div>
  );
};

export default SurveyForm;
