import { Button, Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { styled } from "@mui/system";
import { drawOnPdf, verticalColumnDrawOnPdf } from "../utils/pdfUtils";
import { questions } from "../data/asrs_questions";
import { useAuth } from "../hooks/useAuth";
import { Information } from "../types";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "calc(100vh - 50px)",
  padding: "1rem",
});

const PdfContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
  overflow: "auto",
  backgroundColor: "pink",
  width: "fit-content",
  height: "100%",
});

const StyledPage = styled(Page)({
  maxHeight: "calc(100vh - 250px)", // Adjust this value based on the height of the buttons
  width: "auto",
});

const Buttons = styled(Box)({
  marginTop: "1rem",
});

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const initializeSettings = (settings: Partial<Information>): Information => {
  return {
    name: settings?.name || "",
    proxyName: settings?.proxyName || "",
    age: settings?.age ?? "",
    relationship: settings?.relationship || "",
    time: settings?.time || "",
    email: settings?.email || "",
  };
};

const PDFTest: React.FC = () => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [xCoordinate, setXCoordinate] = useState<number>(0);
  const [yCoordinate, setYCoordinate] = useState<number>(0);

  const { user } = useAuth();
  const userId = user?.uid;

  const userData = useSelector((state: RootState) => state.form);

  const { info } = useSelector((state: RootState) => state.form);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  function goToPreviousPage() {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  }

  function goToNextPage() {
    setPageNumber((prevPageNumber) =>
      Math.min(prevPageNumber + 1, numPages ?? 1)
    );
  }

  const handlePdfClick = (event: React.MouseEvent) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = rect.bottom - event.clientY; // Flip the y-axis
    setXCoordinate(x);
    setYCoordinate(y);
  };

  return (
    <Container style={{ backgroundColor: "green" }}>
      <PdfContainer>
        <Document file="assets/gad7.pdf" onLoadSuccess={onDocumentLoadSuccess}>
          <StyledPage pageNumber={pageNumber} onClick={handlePdfClick} />
        </Document>
        <p>
          Page {pageNumber} of {numPages}
        </p>
        <Buttons>
          <TextField
            label="X Coordinate"
            type="number"
            value={xCoordinate}
            onChange={(e) => setXCoordinate(Number(e.target.value))}
            style={{ marginRight: "1rem" }}
          />
          <TextField
            label="Y Coordinate"
            type="number"
            value={yCoordinate}
            onChange={(e) => setYCoordinate(Number(e.target.value))}
            style={{ marginRight: "1rem" }}
          />
          <Button
            variant="contained"
            onClick={goToPreviousPage}
            disabled={pageNumber <= 1}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            onClick={goToNextPage}
            disabled={pageNumber >= (numPages ?? 1)}
          >
            Next
          </Button>
          <Button
            variant="contained"
            onClick={() => drawOnPdf("assets/gad7.pdf")}
            // onClick={() => verticalColumnDrawOnPdf("assets/mania.pdf")}
            // onClickCapture={() => generateSleepForm(questions)}
            // onClick={() =>
            // generateOnlyLevel1Form(questionsMap.get("cc_level1") || [], info)
            // }
          >
            Draw X
          </Button>
        </Buttons>
      </PdfContainer>
    </Container>
  );
};

export default PDFTest;
