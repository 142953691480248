import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

type ProvidersBoxProps = {
  control: any;
  watch: any;
};

const options = [
  { value: "pcp", label: "Primary Care Provider" },
  { value: "cardiology", label: "Cardiology" },
  { value: "pulmonology", label: "Pulmonology" },
  { value: "allergy", label: "Allergy" },
  { value: "neurology", label: "Neurology" },
  { value: "obgyn", label: "OB/GYN" },
  { value: "naturopath", label: "Naturopath/Alternative" },
];

const ProvidersBox: React.FC<ProvidersBoxProps> = ({ control, watch }) => {
  const MenuProps = {
    anchorOrigin: {
      vertical: "bottom" as const,
      horizontal: "left" as const,
    },
    transformOrigin: {
      vertical: "top" as const,
      horizontal: "left" as const,
    },
    getContentAnchorEl: null,
  };
  return (
    <Box mt={3} mb={3}>
      <Typography variant="h6">Other Providers</Typography>
      <Controller
        name="providers.receivingCare"
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <FormControl margin="dense" sx={{ minWidth: 200 }}>
            <InputLabel>
              Are you receiving care from any other providers?
            </InputLabel>
            <Select
              {...field}
              label="Are you receiving care from any other providers?"
              multiple
              MenuProps={MenuProps}
              renderValue={(selected: any[]) => {
                console.log(selected);
                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected &&
                      (selected as string[]).map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                  </Box>
                );
              }}
              sx={{ minWidth: 200 }}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={field.value.includes(option.value)} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default ProvidersBox;
