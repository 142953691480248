import { QuestionsData } from "../types";

const defaultOptions = [
  "Not at all",
  "Several days",
  "More than half the days",
  "Nearly every day",
];

export const questions: QuestionsData = {
  title: "Patient Health Questionnaire (PHQ-9)",
  formName: "phq9",
  instructions:
    "Over the last two weeks, how often have you been bothered by any of the following problems?",

  sections: [
    {
      sectionId: "section0",
      label: "Questions",
      sectionNumeral: "I",
      score: 0,
      scoreThresholds: [
        { minimum: 0, maximum: 4, evaluation: "Minimal Depression" },
        { minimum: 5, maximum: 9, evaluation: "Mild Depression" },
        { minimum: 10, maximum: 14, evaluation: "Moderate Depression" },
        {
          minimum: 15,
          maximum: 19,
          evaluation: "Moderately Severe Depression",
        },
        { minimum: 20, maximum: 27, evaluation: "Severe Depression" },
      ],
      scoreExplanation:
        "If there are at least 4 checks in the shaded section (including Questions #1 and #2), consider a depressive disorder. Add score to determine severity.  Consider Major Depressive Disorder if there are at least 5 checks in the shaded section (one of which corresponds to Question #1 or #2). Consider other depressive disorder if there are 2-4 checks in the shaded section (one of which corresponds to Question #1 or #2)",
      instructions: "In the past two weeks....",
      questions: [
        {
          question: "Little interest or pleasure in doing things",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Feeling down, depressed, or hopeless",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Trouble falling or staying asleep, or sleeping too much",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Feeling tired or having little energy",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question: "Poor appetite or overeating",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question:
            "Feeling bad about yourself - or that you are a failure or have let yourself or your family down",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question:
            "Trouble concentrating on things, such as reading the newspaper or watching television",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question:
            "Moving or speaking so slowly that other people could have noticed. Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
        {
          question:
            "Thoughts that you would be better off dead or of hurting yourself in some way",
          options: defaultOptions,
          response: 0,
          type: "toggle_group",
        },
      ],
    },
  ],
};
